import { fragment } from "xmlbuilder2";
import sampleRailServicesTableXML from "@/assets/xml/sampleRailServicesTable.xml?raw";
import sampleRailStationsTableXML from "@/assets/xml/sampleRailStationsTable.xml?raw";
import { SF_XML_NAMESPACE } from "@/word/contentControlConstants";

export const generateRailStationsTableHTML = (railStations, tramMetroStops) => {
    const jointStations = [...(railStations || []), ...(tramMetroStops || [])];

    // Sort combined rail station & tram/metro list into order.
    // Only sort if both railStations and tramMetroStops are non-zero.
    if (railStations?.length > 0 && tramMetroStops?.length > 0) {
        jointStations.sort((a, b) => {
            // Check for null values - nulls should be first
            if (a.travel_times.walking_distance_int === null && b.travel_times.walking_distance_int === null) {
                return 0; // Both values are null, considered equal
            } else if (a.travel_times.walking_distance_int === null) {
                return -1; // `a` is null, put it before `b`
            } else if (b.travel_times.walking_distance_int === null) {
                return 1; // `b` is null, put it before `a`
            }

            return a.travel_times.walking_distance_int - b.travel_times.walking_distance_int;
        });
    }

    let html = ``;
    if (jointStations) {
        const newTable = document.createElement("table");
        newTable.classList.add("normal_text");
        newTable.classList.add("rail_stations_table");

        // Create header row
        let row = newTable.insertRow(0);
        let cell;
        const headings = [
            "Station",
            "Distance",
            "Travel time by walking",
            "Travel time by cycling",
            "Travel time by driving",
        ];
        for (let th = 0; th < headings.length; th++) {
            cell = document.createElement("th");
            cell.innerText = headings[th];
            row.appendChild(cell);
        }

        // Add rows
        for (let i = 0; i < jointStations.length; i++) {
            row = newTable.insertRow(-1);
            let td;

            // station name
            td = row.insertCell(-1);
            td.classList.add("station_name");
            td.innerText = jointStations[i].name;

            // distance
            td = row.insertCell(-1);
            td.innerText = jointStations[i].travel_times.walking_distance;

            // travel time by walking
            td = row.insertCell(-1);
            td.innerText = jointStations[i].travel_times.walking_duration;

            // travel time by cycling
            td = row.insertCell(-1);
            td.innerText = jointStations[i].travel_times.cycling_duration;

            // travel time by driving
            td = row.insertCell(-1);
            td.innerText = jointStations[i].travel_times.driving_duration;
        }
        html = newTable.outerHTML;
        newTable.remove();
    }

    return html;
};

export const generateRailServicesTableHTML = (railStations) => {
    let html = ``;

    if (railStations) {
        const serviceTable = document.createElement("table");
        serviceTable.classList.add("normal_text");
        serviceTable.classList.add("rail_services_table");

        // Table header
        const tblHead = document.createElement("thead");
        const tr = document.createElement("tr");

        let th;
        const headings = ["Destination", "Days of Operation", "Frequency", "Journey Time", "Hours of Operation"];
        for (let col = 0; col < headings.length; col++) {
            th = document.createElement("th");
            th.setAttribute("scope", "col");
            th.innerText = headings[col];
            tr.appendChild(th);
        }

        tblHead.appendChild(tr);
        serviceTable.appendChild(tblHead);

        railStations.forEach((station) => {
            const tbody = document.createElement("tbody");
            let tr = document.createElement("tr");
            const stnCell = document.createElement("td");
            stnCell.classList.add("rail_station_name_row");
            stnCell.colSpan = 5;
            stnCell.innerText = station.name;

            tr.appendChild(stnCell);
            tbody.appendChild(tr);

            station.services.forEach((dest) => {
                tr = document.createElement("tr");
                const destCell = document.createElement("td");
                destCell.innerText = dest.destination;
                destCell.classList.add("rail_service_destination");
                tr.appendChild(destCell);

                const daysCell = document.createElement("td");
                daysCell.innerText = dest.days;
                tr.appendChild(daysCell);

                const servicesCell = document.createElement("td");
                servicesCell.innerText = dest.daily_services;
                tr.appendChild(servicesCell);

                const jtimeCell = document.createElement("td");
                jtimeCell.innerText = dest.journey_time;
                tr.appendChild(jtimeCell);

                const timesCell = document.createElement("td");
                let timeString = "";
                if (dest.first_service === dest.last_service) {
                    timeString = dest.first_service;
                } else {
                    timeString = dest.first_service + " - " + dest.last_service;
                }
                timesCell.innerText = timeString;
                tr.appendChild(timesCell);

                tbody.appendChild(tr);
            });
            serviceTable.appendChild(tbody);
        });
        html = serviceTable.outerHTML;
    }

    return html;
};

export const generateRailStationAmenitiesHTML = (railStations) => {
    let html = ``;
    if (railStations) {
        const stationAmenitiesContainer = document.createElement("div");
        stationAmenitiesContainer.classList.add("station_amenities_list_cont");

        railStations.forEach((station) => {
            const stationAmenities = document.createElement("div");
            stationAmenities.classList.add("station_amenities_list");

            const stnHeading = document.createElement("h2");
            stnHeading.innerText = station.name;
            stationAmenities.appendChild(stnHeading);

            const amt = station.amenities;

            if (amt != null) {
                const amenitiesList = document.createElement("ul");

                if ((amt.staff && amt.staff === "Full Time") || amt.staff === "Part Time") {
                    const staff = document.createElement("li");
                    staff.innerText = "Staffed at " + amt.staff.toLowerCase() + " capacity";
                    amenitiesList.appendChild(staff);
                }

                if (amt.info_staff) {
                    const infoStaff = document.createElement("li");
                    infoStaff.innerText = `Customer information available in station from: ${amt.info_staff}`;
                    amenitiesList.appendChild(infoStaff);
                }

                if (amt.info_open) {
                    const infoOpen = document.createElement("li");
                    infoOpen.innerHTML = amt.info_open;
                    amenitiesList.appendChild(infoOpen);
                }

                if (amt.info_open_times && amt.info_open_times.length > 0) {
                    const infoOpenTimes = document.createElement("li");
                    infoOpenTimes.innerText = "Information points are available at the following times:";
                    const infoOpenTimesList = document.createElement("ul");
                    amt.info_open_times.forEach((timeString) => {
                        const timeItem = document.createElement("li");
                        timeItem.innerText = timeString;
                        infoOpenTimesList.appendChild(timeItem);
                    });
                    infoOpenTimes.appendChild(infoOpenTimesList);
                    amenitiesList.appendChild(infoOpenTimes);
                }

                if (amt.ticket_office) {
                    const ticketOffice = document.createElement("li");
                    ticketOffice.innerText = "Ticket office";
                    amenitiesList.appendChild(ticketOffice);
                }

                if (amt.ticket_machine) {
                    const ticketMachine = document.createElement("li");
                    ticketMachine.innerText = "Ticket machine(s) available";
                    amenitiesList.appendChild(ticketMachine);
                }

                if (amt.seated_area) {
                    const seatedArea = document.createElement("li");
                    seatedArea.innerHTML = "<p>Seating areas available</p>";
                    if (amt.seated_area_desc) {
                        seatedArea.innerHTML += amt.seated_area_desc;
                    }
                    amenitiesList.appendChild(seatedArea);
                }

                if (amt.waiting_room) {
                    const waitingRoom = document.createElement("li");
                    waitingRoom.innerText = "Waiting room";
                    amenitiesList.appendChild(waitingRoom);
                }

                if (amt.station_buffet) {
                    const stationBuffet = document.createElement("li");
                    stationBuffet.innerText = "Station buffet";
                    amenitiesList.appendChild(stationBuffet);
                }

                if (amt.toilets) {
                    const toilets = document.createElement("li");
                    toilets.innerText = "Toilets";
                    amenitiesList.appendChild(toilets);
                }

                if (amt.shops) {
                    const shops = document.createElement("li");
                    shops.innerText = "Shop available in station";
                    amenitiesList.appendChild(shops);
                }

                if (amt.step_free_access && amt.step_free_access !== "None of Station") {
                    const sfa = document.createElement("li");
                    sfa.innerHTML =
                        "<p>Step free access is available to " + amt.step_free_access.toLowerCase() + "</p>";
                    if (amt.sfa_description !== null) {
                        sfa.innerHTML += amt.sfa_description;
                    }
                    amenitiesList.appendChild(sfa);
                }

                if (amt.car_spaces > 0) {
                    const car = document.createElement("li");
                    car.innerText = amt.car_spaces + " car parking spaces.";

                    if (amt.car_accessible > 0) {
                        car.innerText += " " + amt.car_accessible + " are accessible parking spaces.";
                    }

                    if (amt.car_cctv) {
                        car.innerText += " Car park covered by CCTV.";
                    }

                    amenitiesList.appendChild(car);
                }

                if (amt.car_charge) {
                    const car_charge = document.createElement("li");
                    if (amt.car_charge === "Free") {
                        car_charge.innerText = "Car park is free";
                    } else {
                        car_charge.innerText = "Daily parking costs " + amt.car_charge;
                    }

                    amenitiesList.appendChild(car_charge);
                }

                if (amt.cycle_spaces > 0) {
                    const cycle = document.createElement("li");
                    cycle.innerText = amt.cycle_spaces + " cycle parking spaces.";

                    if (amt.cycle_sheltered === "Full") {
                        cycle.innerText += " Cycle spaces are fully sheltered.";
                    } else if (amt.cycle_sheltered === "Partial") {
                        cycle.innerText += " Cycle spaces are partially sheltered.";
                    }

                    if (amt.cycle_cctv) {
                        cycle.innerText += " Storage area covered by CCTV.";
                    }

                    if (amt.cycle_storage && amt.cycle_storage.length > 0) {
                        cycle.innerText += " Cycle storage types: ";
                        cycle.innerText += amt.cycle_storage.join(", ").toLowerCase();
                        cycle.innerText += ".";
                    }

                    amenitiesList.appendChild(cycle);
                }

                if (amt.cycle_hire) {
                    const cycleHire = document.createElement("li");
                    cycleHire.innerText = "Cycle hire is available at or near station";
                    amenitiesList.appendChild(cycleHire);
                }

                stationAmenities.appendChild(amenitiesList);
            }

            stationAmenitiesContainer.appendChild(stationAmenities);
        });

        html = stationAmenitiesContainer.outerHTML;
        stationAmenitiesContainer.remove();
    }

    return html;
};

export const generateRailStationsXMLString = (railStations, tramMetroStops) => {
    if (!railStations && !tramMetroStops) {
        return sampleRailStationsTableXML;
    }

    const jointStations = [...(railStations || []), ...(tramMetroStops || [])];

    // Sort combined rail station & tram/metro list into order.
    // Only sort if both railStations and tramMetroStops are non-zero.
    if (railStations?.length > 0 && tramMetroStops?.length > 0) {
        jointStations.sort((a, b) => {
            // Check for null values - nulls should be first
            if (a.travel_times.walking_distance_int === null && b.travel_times.walking_distance_int === null) {
                return 0; // Both values are null, considered equal
            } else if (a.travel_times.walking_distance_int === null) {
                return -1; // `a` is null, put it before `b`
            } else if (b.travel_times.walking_distance_int === null) {
                return 1; // `b` is null, put it before `a`
            }

            return a.travel_times.walking_distance_int - b.travel_times.walking_distance_int;
        });
    }

    const railStationsObject = {
        rail_stations_table: {
            "@xmlns": SF_XML_NAMESPACE,
            station: jointStations
                ? jointStations.map((station) => ({
                      name: station.name,
                      distance: station.travel_times.walking_distance,
                      walking_duration: station.travel_times.walking_duration,
                      cycling_duration: station.travel_times.cycling_duration,
                      driving_duration: station.travel_times.driving_duration,
                  }))
                : [],
        },
    };

    return fragment(railStationsObject).end();
};

export const generateRailServicesXMLString = (railStations) => {
    if (!railStations) {
        return sampleRailServicesTableXML;
    }

    const railServicesObject = {
        rail_services_table: {
            "@xmlns": SF_XML_NAMESPACE,
            service: railStations.flatMap((station) =>
                station.services.map((dest) => ({
                    origin: station.name,
                    destination: dest.destination,
                    days_of_operation: dest.days,
                    frequency: dest.daily_services,
                    journey_time: dest.journey_time,
                    hours_of_operation:
                        dest.first_service === dest.last_service
                            ? dest.first_service
                            : `${dest.first_service} – ${dest.last_service}`,
                }))
            ),
        },
    };

    return fragment(railServicesObject).end();
};
