import { defaults } from 'ol/interaction/defaults'
import View from 'ol/View'
import Map from 'ol/Map'
import Control from 'ol/control/Control'
import Attribution from 'ol/control/Attribution'
import ContextMenu from 'ol-contextmenu'
import { Extent } from 'ol/extent'
import { Raster, Vector as VectorSource, Google } from 'ol/source'
import { Layer, Tile as TileLayer, VectorImage as VectorLayer, WebGLTile as WebGLTileLayer } from 'ol/layer'
import BaseLayer from 'ol/layer/Base.js'
import ImageLayer from 'ol/layer/Image'
import Collection from 'ol/Collection.js'
import { Icon, Style, Fill, Stroke } from 'ol/style'
import RegularShape from 'ol/style/RegularShape'
import CircleStyle from 'ol/style/Circle'
import XYZ from 'ol/source/XYZ'
import OSM from 'ol/source/OSM.js'
import { Projection, transformExtent, useGeographic } from 'ol/proj'
import GeoJSON from 'ol/format/GeoJSON'
import Modify from 'ol/interaction/Modify'
import Select from 'ol/interaction/Select'
import Translate from 'ol/interaction/Translate'
import Rotate from 'ol/control/Rotate.js'
import ScaleLine, { Units } from 'ol/control/ScaleLine.js'
import { toBlob, toPng } from 'html-to-image'
import { bbox } from 'ol/loadingstrategy'
import convert from 'color-convert'
import SchemeflowLegend from '@/maps/sflegend'
import { iconFromConfig } from '@/maps/configIcons'
import Draw from 'ol/interaction/Draw'
import { noModifierKeys, primaryAction, doubleClick } from "ol/events/condition";
import Overlay from "ol/Overlay";
import { nanoid } from "nanoid";

// Import map styles
import { isochroneColors, redlineStyle, drawStyleFunction, OS_LIGHT_MAP_TILES_URL, OS_MAP_ROAD_TILES_URL, OS_OUTDOOR_MAP_TILES_URL, SATELLITE_MAP_TILES_URL, accidentMarker } from '@/maps/constants'
import LayerGroup from 'ol/layer/Group'
import { Feature } from 'ol'
import { Geometry, Point } from 'ol/geom'

import { useEventBus } from '@vueuse/core'
import Interaction from 'ol/interaction/Interaction'
import { highlightedPolygonStyle, polygonEditStyleFunction } from './constants'
import {
  TextAnnotationStyleEditChangeEvent,
  TextAnnotationStyle,
  textAnnotationDefaultStyle,
  getStyleAttributeForStyle,
  getStyleForShapeAnnotationFeature,
  shapeAnnotationDrawLineStyle,
  shapeAnnotationDrawPolygonStyle,
  shapeAnnotationArrowDefaultStyle,
  ShapeAnnotationStyleEditChangeEvent,
  ShapeAnnotationLineStyle,
  ShapeAnnotationPolygonStyle,
  shapeAnnotationSelectedFeatureStyle
} from "@/maps/annotationstyles.ts";

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

// Create the operation function
const colorFilterOperation = (pixels: Array<Array<number>>, data: any) => {
  let pixel = pixels[0]
  if (data.grayscaleFilter) {
    const grayscalePixelValue = 0.2627 * pixels[0][0] + 0.678 * pixels[0][1] + 0.0593 * pixels[0][2]
    pixel[0] = grayscalePixelValue
    pixel[1] = grayscalePixelValue
    pixel[2] = grayscalePixelValue
  }

  // data.alpha is a value between 0 (transparent) and 255 (opaque)
  // If opacity is set and is less than 255
  if (data.alpha && data.alpha < 255) {
    // calculate RGB pixel value from alpha value (assuming white background)
    for (let i = 0; i < 3; i++) {
      pixel[i] = Math.round(pixel[i] * (data.alpha / 255) + 255 * (1 - data.alpha / 255))
    }
  }
  return pixel
}

const osmOverpassBboxLoader = (options: {
  extent: Extent;
  projection: Projection,
  osmQuery: Array<String>,
  vectorSource: VectorSource,
}) => {
  const [minlon, minlat, maxlon, maxlat] = transformExtent(options.extent, options.projection, 'EPSG:4326');
  const url = 'https://overpass-api.de/api/interpreter';

  // Build union queries by combining multiple elements of query array together.
  // Intersection queries can be constructed by giving multiple tags in a single element
  let queryStatements = '('
  options.osmQuery.forEach((queryElement) => {
    queryStatements += `nw${queryElement}(${minlat},${minlon},${maxlat},${maxlon});`
  })
  queryStatements += ');'

  const query = `
    [out:json];
    ${queryStatements}
    out center;
  `;

  fetch(url, {
    method: 'POST',
    body: new URLSearchParams({ data: query }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => response.json())
  .then(data => {
    const features = data.elements.map(element => new Feature({
      geometry: new Point([
        element.type == "node" ? element.lon : element.center.lon,
        element.type == "node" ? element.lat : element.center.lat
      ])
    }));
    options.vectorSource.addFeatures(features);
    return features
  })
  .catch(error => console.error('Error fetching data from OpenStreetMap API:', error));
}

class GoogleLogoControl extends Control {
  constructor() {
    const element = document.createElement('img');
    element.style.pointerEvents = 'none';
    element.style.position = 'absolute';
    element.style.bottom = '5px';
    element.style.left = '5px';
    element.src =
      'https://developers.google.com/static/maps/documentation/images/google_on_white.png';
    super({
      element: element,
    });
  }
}

enum shapeAnnotationType {
  POLYGON = 'polygon',
  LINE = 'line',
  ARROW = 'arrow',
}

export default class SchemeflowMap {
  private zoom: number
  private longitude: number
  private latitude: number
  private map: Map
  private baseMapLayers: LayerGroup
  private overlayLayers: LayerGroup
  private isochroneSource: VectorSource
  public isochroneOriginSource: VectorSource
  public busStopsSource: VectorSource
  public busStopsLayer: VectorLayer<VectorSource>
  private googleSource: Google
  private railStationsSource: VectorSource
  private supermarketsSource: VectorSource
  private primarySchoolsSource: VectorSource
  private secondarySchoolsSource: VectorSource
  private pharmaciesSource: VectorSource
  private postOfficesSource: VectorSource
  private chargePointsSource: VectorSource
  private ferryPortsSource: VectorSource
  private metroStopsSource: VectorSource
  private osmBusStopsSource: VectorSource
  private osmRailStationsSource: VectorSource
  private osmMetroStationsSource: VectorSource
  private osmLightRailStationsSource: VectorSource
  private osmTramStopsSource: VectorSource
  private osmChargePointsSource: VectorSource
  private osmFerryPortsSource: VectorSource
  private osmRestaurantsSource: VectorSource
  private osmBarsAndCafesSource: VectorSource
  private osmHospitalsSource: VectorSource
  private osmHealthClinicsSource: VectorSource
  private osmKindergartenSource: VectorSource
  private osmSchoolsSource: VectorSource
  private osmCollegesSource: VectorSource
  private osmUniversitiesSource: VectorSource
  private osmSupermarketsSource: VectorSource
  private osmFireStationsSource: VectorSource
  private osmPlacesOfWorshipSource: VectorSource
  private osmCommunityCentresSource: VectorSource
  private roadAccidentsSource: VectorSource
  public roadAccidentCentreSource: VectorSource
  private osmRasterSource: Raster
  private osLightRasterSource: Raster
  private osRoadRasterSource: Raster
  private osOutdoorRasterSource: Raster
  private satelliteRasterSource: Raster
  public redlinePolygonSource: VectorSource
  private redlinePolygonLayer: VectorLayer<VectorSource>
  public siteLocationPointSource: VectorSource
  private siteLocationPointLayer: VectorLayer<VectorSource>
  private sfLegend: SchemeflowLegend
  private googleControl: GoogleLogoControl
  private attribution: Attribution
  private mapKey: string | undefined
  private redlineDrawInteraction: Draw
  private modifyInteraction: Modify
  private moveMapInteractions: Collection<Interaction>
  private drawKeyEventAbortController: AbortController
  private textAnnotationEditEventAbortController: AbortController
  private contextmenu: ContextMenu
  public redlinePolygonEdit: boolean
  private siteLocationModify: Modify
  private textAnnotationDrawInteraction: Draw
  private annotationDrawKeyEventAbortController: AbortController
  public annotationDrawingActive: string | null
  private editingTextAnnotation: string         // id of annotation being edited, or null if not in edit mode
  public textAnnotationsSource: VectorSource    // Used only for syncing change to db, annotations are Overlays, not vector features
  private draggedTextAnnotation: Overlay
  private draggedTextAnnotationOffset: { x: number, y: number }
  private textAnnotationStyleAbortController: AbortController
  private shapeAnnotationDrawInteraction: Draw
  public shapeAnnotationsSource: VectorSource
  private shapeAnnotationStyleEditId: string | null
  private shapeAnnotationSelectInteraction: Select
  private shapeAnnotationTranslateInteraction: Translate
  private shapeAnnotationModifyInteraction: Modify

  constructor(options: { redlinePolygonSource: VectorSource; scaleBarUnits: Units | undefined, mapKey: string | undefined, }) {
    this.drawKeyEventAbortController = new AbortController();
    this.textAnnotationEditEventAbortController = new AbortController();
    this.annotationDrawKeyEventAbortController = new AbortController();
    this.textAnnotationStyleAbortController = new AbortController();
    useGeographic()
    this.redlinePolygonSource = options.redlinePolygonSource
    this.isochroneSource = new VectorSource({ wrapX: false })
    this.mapKey = options.mapKey;

    // const geojsonFormat = new GeoJSON({
    // featureProjection: 'EPSG:3857',
    // dataProjection: 'EPSG:3857',
    // });

    // TODO fix projections
    const geojsonFormat = new GeoJSON()

    this.siteLocationPointSource = new VectorSource({
      format: geojsonFormat
    })

    this.busStopsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/bus_stops?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.busStopsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading bus stops. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.railStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/rail_stations?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.railStationsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading rail stations. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmBusStopsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["highway"="bus_stop"]'],
          vectorSource: this.osmBusStopsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmRailStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          // Rail stations which are neither subway stations nor light rail stations
          osmQuery: ['["railway"="station"]["station"!="subway"]["station"!="light_rail"]'],
          vectorSource: this.osmRailStationsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmMetroStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["railway"="station"]["station"="subway"]'],
          vectorSource: this.osmMetroStationsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmLightRailStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["railway"="station"]["station"="light_rail"]'],
          vectorSource: this.osmLightRailStationsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmTramStopsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["railway"="tram_stop"]'],
          vectorSource: this.osmTramStopsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.supermarketsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/supermarkets?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.supermarketsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading supermarkets. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.pharmaciesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="pharmacy"]'],
          vectorSource: this.pharmaciesSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.postOfficesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/post_offices?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.postOfficesSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading post offices. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.primarySchoolsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/primary_schools?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.primarySchoolsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading primary schools. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.secondarySchoolsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/secondary_schools?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.secondarySchoolsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading secondary schools. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.chargePointsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/charge_points?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.chargePointsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading charge points. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.ferryPortsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/ferry_ports?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.ferryPortsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading ferry ports. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.metroStopsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/metro_stops?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.metroStopsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading metro stops. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmChargePointsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="charging_station"]'],
          vectorSource: this.osmChargePointsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmFerryPortsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="ferry_terminal"]'],
          vectorSource: this.osmFerryPortsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmRestaurantsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="bar"]', '["amenity"="cafe"]'],
          vectorSource: this.osmRestaurantsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmBarsAndCafesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="restaurant"]', '["amenity"="fast_food"]'],
          vectorSource: this.osmBarsAndCafesSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmHospitalsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="hospital"]'],
          vectorSource: this.osmHospitalsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmHealthClinicsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="clinic"]', '["amenity"="doctors"]'],
          vectorSource: this.osmHealthClinicsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmKindergartenSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="kindergarten"]'],
          vectorSource: this.osmKindergartenSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmSchoolsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="school"]'],
          vectorSource: this.osmSchoolsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmCollegesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="college"]'],
          vectorSource: this.osmCollegesSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmUniversitiesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="university"]'],
          vectorSource: this.osmUniversitiesSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmSupermarketsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["shop"="supermarket"]', '["shop"="convenience"]'],
          vectorSource: this.osmSupermarketsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmFireStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="fire_station"]'],
          vectorSource: this.osmFireStationsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmPlacesOfWorshipSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="place_of_worship"]'],
          vectorSource: this.osmPlacesOfWorshipSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmCommunityCentresSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="community_centre"]'],
          vectorSource: this.osmCommunityCentresSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.roadAccidentsSource = new VectorSource({
      wrapX: false
    })

    // Road accident centre source is for user drawing interaction to set a
    // new point for road accident location analysis
    this.roadAccidentCentreSource = new VectorSource({
      wrapX: false
    })

    // The centre point can only be a single point, so when a point is added
    // to the source, delete all other points
    this.roadAccidentCentreSource.on('addfeature', (newFeature) => {
      this.roadAccidentCentreSource.forEachFeature((feat) => {
        if (feat != newFeature.feature) {
          this.roadAccidentCentreSource.removeFeature(feat)
        }
      })
    })

    this.isochroneOriginSource = new VectorSource({
      wrapX: false
    })

    this.textAnnotationsSource = new VectorSource({
      format: geojsonFormat,
      wrapX: false,
    });

    // when a new point is added to the isochrone source, all previous
    // points should be removed so only the latest one is displayed
    this.isochroneOriginSource.on('addfeature', (newFeature) => {
      this.isochroneOriginSource.forEachFeature((feat) => {
        if (feat != newFeature.feature) {
          this.isochroneOriginSource.removeFeature(feat)
        }
      })
    })

    this.shapeAnnotationsSource = new VectorSource({
      format: geojsonFormat,
      wrapX: false,
    });

    this.osmRasterSource = new Raster({
      sources: [new OSM()],
      operation: colorFilterOperation
    })

    this.osLightRasterSource = new Raster({
      sources: [
        new XYZ({
          url: OS_LIGHT_MAP_TILES_URL,
          crossOrigin: 'anonymous'
        })
      ],
      operation: colorFilterOperation
    })

    this.osOutdoorRasterSource = new Raster({
      sources: [
        new XYZ({
          url: OS_OUTDOOR_MAP_TILES_URL,
          crossOrigin: 'anonymous'
        })
      ],
      operation: colorFilterOperation
    })

    this.osRoadRasterSource = new Raster({
      sources: [
        new XYZ({
          url: OS_MAP_ROAD_TILES_URL,
          crossOrigin: 'anonymous'
        })
      ],
      operation: colorFilterOperation
    })

    this.satelliteRasterSource = new Raster({
      sources: [
        new XYZ({
          url: SATELLITE_MAP_TILES_URL,
          crossOrigin: 'anonymous'
        })
      ],
      operation: colorFilterOperation
    })

    this.googleSource = new Google({
      key: GOOGLE_MAPS_API_KEY,
      scale: 'scaleFactor2x',
      highDpi: true,
      styles: [
        {
          featureType: "all",
          elementType: "geometry",
          stylers: [
            { gamma: 0.5 },
          ]
        }
      ],
    })

    // Define layer group for base layer options
    this.baseMapLayers = new LayerGroup({
      layers: [
        // OSM
        new ImageLayer({
          visible: true, // Default = OSM
          properties: {
            name: 'OSM'
          },
          source: this.osmRasterSource,
          minZoom: 3,
          maxZoom: 20
        }),
        // OS Light
        new ImageLayer({
          visible: false,
          properties: {
            name: 'OSLight'
          },
          source: this.osLightRasterSource,
          minZoom: 7,
          maxZoom: 20
        }),
        // OS Outdoor
        new ImageLayer({
          visible: false,
          properties: {
            name: 'OSOutdoor'
          },
          source: this.osOutdoorRasterSource,
          minZoom: 7,
          maxZoom: 20
        }),
        // OS Road
        new ImageLayer({
          visible: false,
          properties: {
            name: 'OSRoad'
          },
          source: this.osRoadRasterSource,
          minZoom: 7,
          maxZoom: 20
        }),
        // Satellite raster
        new ImageLayer({
          visible: false,
          properties: {
            name: 'Satellite'
          },
          source: this.satelliteRasterSource,
          minZoom: 3,
          maxZoom: 18
        }),
        // Google maps
        new WebGLTileLayer({
          visible: false,
          properties: {
            name: 'Google'
          },
          source: this.googleSource,
          minZoom: 3,
          maxZoom: 22
        })
      ]
    })

    this.siteLocationPointLayer = new VectorLayer({
      visible: false,
      source: this.siteLocationPointSource,
      style: iconFromConfig({ item: "site_location", mapKey: this.mapKey }),
      properties: {
        name: 'site_marker'
      }
    })

    this.redlinePolygonLayer = new VectorLayer({
      minZoom: 12,
      visible: false,
      source: this.redlinePolygonSource,
      style: redlineStyle,
      properties: {
        name: 'redline'
      }
    })

    this.busStopsLayer = new VectorLayer({
      minZoom: 12,
      visible: false,
      source: this.busStopsSource,
      style: iconFromConfig({ item: "bus_stop", mapKey: this.mapKey }),
      properties: {
        name: 'bus_stops'
      }
    })

    this.overlayLayers = new LayerGroup({
      layers: [
        // Isochrones
        new VectorLayer({
          visible: false,
          source: this.isochroneSource,
          style: function (feature) {
            const featId = feature.getId()

            if (featId < 4) {
              // polygon
              return new Style({
                fill: new Fill({
                  color: [...convert.hex.rgb(isochroneColors[feature.getId()!].substring(0, 7)), parseInt(isochroneColors[feature.getId()!].substring(7, 9), 16) / 255]
                }),
                stroke: null
              })
            } else {
              // outer line
              return new Style({
                fill: null,
                stroke: new Stroke({
                  color: 'rgba(180,180,180)'
                })
              })
            }
          },
          properties: {
            name: 'isochrones'
          }
        }),

        // Red line
        this.redlinePolygonLayer,

        this.busStopsLayer,

        new VectorLayer({
          minZoom: 9,
          visible: false,
          source: this.railStationsSource,
          style: iconFromConfig({ item: "rail_station", mapKey: this.mapKey }),
          properties: {
            name: 'rail_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.metroStopsSource,
          style: iconFromConfig({ item: "metro_station", mapKey: this.mapKey }),
          properties: {
            name: 'metro_stops'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmBusStopsSource,
          style: iconFromConfig({ item: "bus_stop", mapKey: this.mapKey }),
          properties: {
            name: 'osm_bus_stops'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmRailStationsSource,
          style: iconFromConfig({ item: "rail_station", mapKey: this.mapKey }),
          properties: {
            name: 'osm_rail_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmMetroStationsSource,
          style: iconFromConfig({ item: "metro_station", mapKey: this.mapKey }),
          properties: {
            name: 'osm_metro_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmLightRailStationsSource,
          style: iconFromConfig({ item: "light_rail_station", mapKey: this.mapKey }),
          properties: {
            name: 'osm_light_rail_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmTramStopsSource,
          style: iconFromConfig({ item: "tram_stop", mapKey: this.mapKey }),
          properties: {
            name: 'osm_tram_stops'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.supermarketsSource,
          style: iconFromConfig({ item: "supermarket", mapKey: this.mapKey }),
          properties: {
            name: 'supermarkets'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.pharmaciesSource,
          style: iconFromConfig({ item: "pharmacy", mapKey: this.mapKey }),
          properties: {
            name: 'pharmacies'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.postOfficesSource,
          style: iconFromConfig({ item: "post_office", mapKey: this.mapKey }),
          properties: {
            name: 'post_offices'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.primarySchoolsSource,
          style: iconFromConfig({ item: "primary_school", mapKey: this.mapKey }),
          properties: {
            name: 'primary_schools'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.secondarySchoolsSource,
          style: iconFromConfig({ item: "secondary_school", mapKey: this.mapKey }),
          properties: {
            name: 'secondary_schools'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.chargePointsSource,
          style: iconFromConfig({ item: "charge_point", mapKey: this.mapKey }),
          properties: {
            name: 'charge_points'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.ferryPortsSource,
          style: iconFromConfig({ item: "ferry_port", mapKey: this.mapKey }),
          properties: {
            name: 'ferry_ports'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmChargePointsSource,
          style: iconFromConfig({ item: "charge_point", mapKey: this.mapKey }),
          properties: {
            name: 'osm_charge_points'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmFerryPortsSource,
          style: iconFromConfig({ item: "ferry_port", mapKey: this.mapKey }),
          properties: {
            name: 'osm_ferry_ports'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmRestaurantsSource,
          style: iconFromConfig({ item: "restaurant", mapKey: this.mapKey }),
          properties: {
            name: 'osm_restaurants'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmBarsAndCafesSource,
          style: iconFromConfig({ item: "cafe", mapKey: this.mapKey }),
          properties: {
            name: 'osm_bars_cafes'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmHospitalsSource,
          style: iconFromConfig({ item: "hospital", mapKey: this.mapKey }),
          properties: {
            name: 'osm_hospitals'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmHealthClinicsSource,
          style: iconFromConfig({ item: "clinic", mapKey: this.mapKey }),
          properties: {
            name: 'osm_clinics'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmKindergartenSource,
          style: iconFromConfig({ item: "kindergarten", mapKey: this.mapKey }),
          properties: {
            name: 'osm_kindergarten'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmSchoolsSource,
          style: iconFromConfig({ item: "school", mapKey: this.mapKey }),
          properties: {
            name: 'osm_schools'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmCollegesSource,
          style: iconFromConfig({ item: "college", mapKey: this.mapKey }),
          properties: {
            name: 'osm_colleges'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmUniversitiesSource,
          style: iconFromConfig({ item: "university", mapKey: this.mapKey }),
          properties: {
            name: 'osm_universities'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmSupermarketsSource,
          style: iconFromConfig({ item: "shop", mapKey: this.mapKey }),
          properties: {
            name: 'osm_supermarkets'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmFireStationsSource,
          style: iconFromConfig({ item: "fire_station", mapKey: this.mapKey }),
          properties: {
            name: 'osm_fire_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmPlacesOfWorshipSource,
          style: iconFromConfig({ item: "place_of_worship", mapKey: this.mapKey }),
          properties: {
            name: 'osm_places_of_worship'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmCommunityCentresSource,
          style: iconFromConfig({ item: "community_centre", mapKey: this.mapKey }),
          properties: {
            name: 'osm_community_centres'
          }
        }),

        // road accident location markers
        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.roadAccidentsSource,
          style: function (feature) {
            if (feature.get('visible')) {
              return accidentMarker[feature.get('severity')]
            } else {
              return null
            }
          },
          properties: {
            name: 'road_accidents'
          }
        }),

        // road accident centre point location marker
        new VectorLayer({
          visible: false,
          source: this.roadAccidentCentreSource,
          // Style: red cross-hairs
          style: new Style({
            image: new RegularShape({
              stroke: new Stroke({ color: 'red', width: 2 }),
              points: 4,
              radius: 10,
              radius2: 0,
              angle: 0
            })
          }),
          properties: {
            name: 'collision_centre'
          }
        }),

        // isochrone origin marker
        new VectorLayer({
          visible: false,
          source: this.isochroneOriginSource,
          // Style: red crosshairs
          style: new Style({
            image: new RegularShape({
              stroke: new Stroke({ color: 'red', width: 2 }),
              points: 4,
              radius: 10,
              radius2: 0,
              angle: 0
            })
          }),
          properties: {
            name: 'isochrone_origin'
          }
        }),

        // Shape annotations
        new VectorLayer({
          visible: true,
          source: this.shapeAnnotationsSource,
          style: getStyleForShapeAnnotationFeature,
          properties: {
            name: 'shape_annotations',
          }
        }),

       // Site location (point) - on top!
        this.siteLocationPointLayer
      ]
    })

    this.sfLegend = new SchemeflowLegend()

    let layers: Array<BaseLayer> | Collection<BaseLayer> | LayerGroup | undefined = [this.baseMapLayers, this.overlayLayers]

    // Set up north arrow
    const colors = ['black', 'darkgrey', 'lightgrey', 'white']
    let compassImg = document.createElement('div')
    for (let style = 1; style < 9; style++) {
      colors.forEach((color) => {
        const arrowImg = document.createElement('img')
        arrowImg.setAttribute('src', '/images/northarrows/northarrow-style' + style + '-' + color + '.svg')
        arrowImg.setAttribute('class', 'north_arrow_style' + style + '_' + color)
        compassImg.appendChild(arrowImg)
      })
    }

    const rotateControl = new Rotate({
      autoHide: false,
      resetNorth: () => {}, // Do nothing when clicked
      label: compassImg,
      tipLabel: 'Compass'
    })

    const scaleLine = new ScaleLine({
      bar: true,
      minWidth: 100,
      units: options.scaleBarUnits,
    })

    this.googleControl = new GoogleLogoControl();
    this.attribution = new Attribution({
      collapsible: false,
    });

    this.siteLocationModify = new Modify({
      pixelTolerance: 20,
      source: this.siteLocationPointSource,
      style: function (feature) {
        return null
      }
    })

    const removeFeature = function (obj) {
      this.removeFeature(obj.data.feature);
    };
    const removePolygonItem = {
        text: 'Delete polygon',
        classname: 'hover:bg-gray-200 hover:text-black', // add some CSS rules
        icon: '/images/icons/trash.svg',
        callback: removeFeature.bind(this.redlinePolygonSource),
    };

    const handleEditTextAnnotationItem = (obj) => {
      this.editTextAnnotation(obj.data.id);
    };

    const textAnnotationEditItem = {
        text: 'Edit label',
        classname: 'hover:bg-gray-200 hover:text-black', // CSS rules for item text
        icon: '/images/icons/edit.svg',
        callback: handleEditTextAnnotationItem,
    };

    const handleStyleTextAnnotationItem = (obj) => {
      this.styleTextAnnotation(obj.data.id);
    };

    const textAnnotationStyleItem = {
      text: 'Label style',
      classname: 'hover:bg-gray-200 hover:text-black', // CSS rules for item text
      icon: '/images/icons/paintbrush.svg',
      callback: handleStyleTextAnnotationItem,
    };

    const handleDeleteTextAnnotationItem = (obj) => {
      this.deleteTextAnnotation(obj.data.id);
    }

    const textAnnotationDeleteItem = {
      text: 'Delete label',
      classname: 'hover:bg-gray-200 hover:text-black', // CSS rules for item text
      icon: '/images/icons/trash.svg',
      callback: handleDeleteTextAnnotationItem,
    };

    const handleEditShapeAnnotationItem = (obj) => {
      // Ensure feature isn't being style edited when shape editing
      this.finishStyleShapeAnnotation();
      const selectedFeatures = this.shapeAnnotationSelectInteraction.getFeatures();
      selectedFeatures.extend([obj.data.feature]);
    };

    const shapeAnnotationEditItem = {
      text: 'Edit annotation points',
      classname: 'hover:bg-gray-200 hover:text-black', // CSS rules for item text
      icon: '/images/icons/arrows-pointing-out.svg',
      callback: handleEditShapeAnnotationItem,
    };

    const handleStyleShapeAnnotationItem = (obj) => {
      this.styleShapeAnnotation(obj.data.feature);
    };

    const shapeAnnotationStyleItem = {
      text: 'Annotation style',
      classname: 'hover:bg-gray-200 hover:text-black', // CSS rules for item text
      icon: '/images/icons/paintbrush.svg',
      callback: handleStyleShapeAnnotationItem,
    };

    const shapeAnnotationDeleteItem = {
      text: 'Delete annotation',
      classname: 'hover:bg-gray-200 hover:text-black', // CSS rules for item text
      icon: '/images/icons/trash.svg',
      callback: removeFeature.bind(this.shapeAnnotationsSource),
    }

    this.contextmenu = new ContextMenu({
      width: 170,
      defaultItems: false,
      items: [],
    });
    this.contextmenu.set("active", true);
    this.redlinePolygonEdit = false;

    // Set up move map interactions but don't add them to the map yet
    this.moveMapInteractions = new Collection();
    defaults({
      altShiftDragRotate: false,
      doubleClickZoom: false,
      keyboard: false,
      mouseWheelZoom: true,
    }).forEach((interaction) => this.moveMapInteractions.push(interaction))

    // Create map
    this.map = new Map({
      layers: layers,
      controls: [rotateControl, scaleLine, this.sfLegend, this.contextmenu],
      interactions: [],
      pixelRatio: 2,
      view: new View({
        zoom: this.zoom,
        center: [this.longitude, this.latitude]
      })
    })

    // Add move map interactions
    this.moveMapInteractions.forEach((interaction) => {
      this.map.addInteraction(interaction);
    });

    // Set up annotation interactions
    this.setupTextAnnotationMouseEvents();
    this.setupShapeAnnotationInteractions();

    const redlineEditable = () => this.redlinePolygonEdit;
    const menuHandleGetTextAnnotationAtCursor = (pixel) => this.getTextAnnotationAtCursor(pixel);

    this.contextmenu.on('beforeopen', function (event) {
      const layerFilter = (layer) => {
        if (redlineEditable() && layer.get('name') === 'redline') {
          return true;
        }
        if (layer.get('name') === 'shape_annotations') {
          return true;
        }
        if (menuHandleGetTextAnnotationAtCursor(event.coordinate)) {
          return true;
        }
        return false;
      }
      const feature = this.map.forEachFeatureAtPixel(event.pixel, function (ft, l) {
        return ft;
      }, { layerFilter: layerFilter });
      // Check that the context menu is active and that a feature was found
      if (feature || menuHandleGetTextAnnotationAtCursor(event.pixel)) {
        this.enable();
      } else {
        this.disable();
      }
      // Don't allow normal browser right click context menu to open
      event.preventDefault();
    })

    this.contextmenu.on('open', function (event) {
      this.clear()
      const redlineLayerFilter = (layer) => {
        return layer.get('name') === 'redline';
      };
      const redlinePolygonFeature = this.map.forEachFeatureAtPixel(event.pixel, function (ft, l) {
        return ft;
      }, { layerFilter: redlineLayerFilter });

      const shapeAnnotationsLayerFilter = (layer) => {
        return layer.get('name') === 'shape_annotations';
      };
      const shapeAnnotationFeature = this.map.forEachFeatureAtPixel(event.pixel, function (ft, l) {
        return ft;
      }, { layerFilter: shapeAnnotationsLayerFilter });

      const overlayIdOrNull = menuHandleGetTextAnnotationAtCursor(event.pixel);

      if (redlinePolygonFeature) {
        redlinePolygonFeature.setStyle(highlightedPolygonStyle);
        removePolygonItem.data = { feature: redlinePolygonFeature };
        this.push(removePolygonItem);
      }

      if (shapeAnnotationFeature) {
        shapeAnnotationEditItem.data = { feature: shapeAnnotationFeature };
        shapeAnnotationStyleItem.data = { feature: shapeAnnotationFeature };
        shapeAnnotationDeleteItem.data = { feature: shapeAnnotationFeature };
        this.extend([
          shapeAnnotationEditItem,
          shapeAnnotationStyleItem,
          shapeAnnotationDeleteItem,
        ]);
      }

      if (overlayIdOrNull) {
        textAnnotationEditItem.data = { id: overlayIdOrNull };
        textAnnotationStyleItem.data = { id: overlayIdOrNull };
        textAnnotationDeleteItem.data = { id: overlayIdOrNull };

        const textAnnotationItems = [
          textAnnotationEditItem,
          textAnnotationStyleItem,
          textAnnotationDeleteItem,
        ];

        if (redlinePolygonFeature) {
          textAnnotationItems.splice(0, 0, '-');
        }
        this.extend(textAnnotationItems);

        // If context menu is opened on an overlay, close click events are only
        // enabled for the overlay's element (so trigger on neither the map
        // itself, nor any other overlays). Add an additional click handler
        // which will close menu when clicking elsewhere on map
        const mapContainerCollection = document.getElementsByClassName('map');
        if (mapContainerCollection.length) {
          const contextMenuOverlayClickOffAbortController = new AbortController();
          const mapElement = mapContainerCollection[0];
          mapElement.addEventListener('mousedown', (e) => {
            if (this.isOpen) {
              this.closeMenu();
            }
            // If menu was open, now closed and event listener no longer needed.
            // If menu wasn't open, it was shut by some other means, and event listener no longer needed.
            contextMenuOverlayClickOffAbortController.abort();
          }, { signal: contextMenuOverlayClickOffAbortController.signal });
        }
      }
    });

    // Reset polygon style when context menu is closed
    const resetPolygonStyle = function (event) {
      this.getFeatures().forEach((feature) => {
        feature.setStyle(null);
      });
    }

    this.contextmenu.on('close', resetPolygonStyle.bind(this.redlinePolygonSource));
  }

  createErrorEvent(detail: string): void {
    let errorBus = useEventBus('error')
    errorBus.emit(detail)
  }

  setBaseLayer(layerName: string): void {
    this.baseMapLayers.getLayers().forEach((layer) => {
      if (layer.get('name') === layerName) {
        layer.setVisible(true)
        // Set min and max zoom of the map to equal the base layer
        let currentZoom = this.map.getView().getZoom()
        let currentCenter = this.map.getView().getCenter()
        let minZoom = layer.getMinZoom()
        let maxZoom = layer.getMaxZoom()
        this.map.setView(
          new View({
            zoom: currentZoom,
            center: currentCenter,
            // Had to ±0.25 to account for rounding errors
            minZoom: minZoom + 0.25,
            maxZoom: maxZoom - 0.25
          })
        )
      } else {
        layer.setVisible(false)
      }
    })

    // Show Google logo if Google map
    if (layerName == "Google") {
      this.googleControl.setMap(this.map)
      this.attribution.setMap(this.map)
    } else {
      this.googleControl.setMap(null)
      this.attribution.setMap(null)
    }
  }

  toggleOverlayLayer(overlayLayerName: string, visible: boolean): void {
    this.overlayLayers.getLayers().forEach((layer) => {
      if (layer.get('name') === overlayLayerName) {
        layer.setVisible(visible)
      }
    })
  }

  getMap(): Map {
    return this.map
  }

  setCenter(longitude: number, latitude: number): void {
    this.longitude = longitude
    this.latitude = latitude
    this.map.getView().setCenter([this.longitude, this.latitude])
  }

  setZoom(zoom: number): void {
    this.zoom = zoom
    this.map.getView().setZoom(this.zoom)
  }

  setPinLocation(longitude: number, latitude: number): void {
    this.siteLocationPointSource.clear()
    this.siteLocationPointSource.addFeature(
      new Feature({
        geometry: new Point([longitude, latitude])
      })
    )
  }

  setTarget(target?: string | HTMLElement | undefined): void {
    this.map.setTarget(target)
  }

  clearIsochrone() {
    this.isochroneSource.clear()
  }

  setIsochrone(isochroneGeoJSON: any) {
    this.clearIsochrone()
    const features = new GeoJSON().readFeatures(isochroneGeoJSON, {
      dataProjection: this.map.getView().getProjection(),
      featureProjection: this.map.getView().getProjection()
    })
    this.isochroneSource.addFeatures(features)
  }

  async exportPNG(filename: string): void {
    this.getPNGImageB64().then((dataURL) => {
      const link = document.createElement('a')
      link.href = dataURL
      link.download = filename
      link.click()
    })
  }

  async getPNGImageB64(): any {
    const exportCanvasScale = 2.0
    const [origMapWidth, origMapHeight] = this.map.getSize()

    const exportOptions = {
      skipFonts: true,
      canvasWidth: origMapWidth * exportCanvasScale,
      canvasHeight: origMapHeight * exportCanvasScale
    }

    // A strange bug in the html-to-image library
    // (https://github.com/bubkoo/html-to-image/issues/432) means maps are
    // exported without the background (only showing controls on a blank
    // image) on the first export, but export correctly on 2nd or 3rd
    // attempt in Safari (and potentially Firefox) browsers (not an issue in
    // Chrome). Exporting 3 times seems to solve the issue here, we can
    // throw away the first two and return the third.
    await toPng(this.map.getTargetElement(), exportOptions)
    await toPng(this.map.getTargetElement(), exportOptions)

    return toPng(this.map.getTargetElement(), exportOptions)
  }

  async exportBlob(): Promise<Blob | null> {
    const exportCanvasScale = 2.0
    const [origMapWidth, origMapHeight] = this.map.getSize()

    const exportOptions = {
      skipFonts: true,
      canvasWidth: origMapWidth * exportCanvasScale,
      canvasHeight: origMapHeight * exportCanvasScale
    }

    // A strange bug in the html-to-image library
    // (https://github.com/bubkoo/html-to-image/issues/432) means maps are
    // exported without the background (only showing controls on a blank
    // image) on the first export, but export correctly on 2nd or 3rd
    // attempt in Safari (and potentially Firefox) browsers (not an issue in
    // Chrome). Exporting 3 times seems to solve the issue here, we can
    // throw away the first two and return the third.
    await toBlob(this.map.getTargetElement(), exportOptions)
    await toBlob(this.map.getTargetElement(), exportOptions)

    return toBlob(this.map.getTargetElement(), exportOptions)
  }

  setBusStopsStyle(func: Function): void {
    this.busStopsLayer.setStyle(func)
    this.busStopsLayer.changed()
  }

  setLegendItems(items: Array<olLegendItemOptions>): void {
    this.sfLegend.clearLegend()

    items.forEach((item) => {
      this.sfLegend.addItem(item)
    })
  }

  setLegendTitle(title: string): void {
    this.sfLegend.setTitle(title);
  }

  setIsochroneStyles(styleList: Array<Style>): void {
    // Apply list of styles to isochrones
    this.overlayLayers.getLayers().forEach((layer) => {
      if (layer.get('name') === 'isochrones') {
        layer.setStyle(function (feature) {
          const featId = feature.getId()

          // if featId < 4, it's a polygon, if > 4, a line. For
          // polygons, need to apply appropriate fill style with no
          // outline, and for lines, apply stroke style with no
          // fill.
          if (featId < 4) {
            // polygon, isochrone fill
            return new Style({
              fill: styleList[featId % 4].getFill(),
              stroke: null
            })
          } else {
            // line, ischrone outer edge line
            return new Style({
              fill: null,
              stroke: styleList[featId % 4].getStroke()
            })
          }
        })
      }
    })
  }

  setColorFilters(grayscaleFilter: boolean, alpha: number): void {
    // Set each raster source operation to the new function
    ;[this.osmRasterSource, this.osLightRasterSource, this.osOutdoorRasterSource, this.osRoadRasterSource, this.satelliteRasterSource].forEach((source) => {
      source.on('beforeoperations', function (event) {
        event.data.alpha = alpha
        event.data.grayscaleFilter = grayscaleFilter
      })
      source.changed()
    })
  }

  setIsochroneOrigin(longitude: number, latitude: number): void {
    this.isochroneOriginSource.clear()
    this.isochroneOriginSource.addFeature(
      new Feature({
        geometry: new Point([longitude, latitude])
      })
    )
  }

  getIsochroneOrigin(): [number | undefined, number | undefined] {
    const features = this.isochroneOriginSource.getFeatures()

    if (features.length == 0) {
      return [undefined, undefined]
    } else {
      // features should have a length of one, so the first element will
      // always be the one we want
      return features[0].getGeometry().getCoordinates()
    }
  }

  public disableMovingSiteLocationPoint(): void {
    this.map.removeInteraction(this.siteLocationModify);
  }

  public enableMovingSiteLocationPoint(): void {
    this.map.addInteraction(this.siteLocationModify);
  }

  public clearRoadAccidents(): void {
    this.roadAccidentsSource.clear()
  }

  public setRoadAccidents(roadAccidentGeoJSON: any): void {
    this.clearRoadAccidents()

    // Ensure GeoJSON features have the right projection for drawing on map view
    const features = new GeoJSON().readFeatures(roadAccidentGeoJSON, {
      dataProjection: this.map.getView().getProjection(),
      featureProjection: this.map.getView().getProjection()
    })

    // Add accident features to map
    this.roadAccidentsSource.addFeatures(features)
  }

  // Set the centre point of collision analysis, from which the radius is measured
  public setCollisionCentrePoint(longitude: number, latitude: number): void {
    this.roadAccidentCentreSource.clear()
    this.roadAccidentCentreSource.addFeature(
      new Feature({
        geometry: new Point([longitude, latitude])
      })
    )
  }

  // Get the centre point for collision analysis, drawn by map interaction
  // onto the raodAccidentCentreSource
  public getCollisionCentrePoint(): [number | undefined, number | undefined] {
    const features = this.roadAccidentCentreSource.getFeatures()

    if (features.length == 0) {
      return [undefined, undefined]
    } else {
      // features should have a length of one, so the first element will
      // always be the one we want
      return features[0].getGeometry().getCoordinates()
    }
  }

  private enterHandlerFn(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.redlineDrawInteraction.finishDrawing();
    }
  }

  private escapeHandlerFn(e: KeyboardEvent): void {
    if (e.key === 'Escape') {
      e.preventDefault();
      this.redlineDrawInteraction.abortDrawing();
    }
  }

  private backspaceHandlerFn(e: KeyboardEvent): void {
    if (e.key === 'Backspace') {
      e.preventDefault();
      this.redlineDrawInteraction.removeLastPoint();
    }
  }

  public enableEditingRedLine(): void {
    // Create draw interaction
    this.redlineDrawInteraction = new Draw({
      source: this.redlinePolygonSource,
      type: "Polygon",
      style: drawStyleFunction,
      stopClick: true,
      snapTolerance: 6,
      condition: (e) => noModifierKeys(e) && primaryAction(e),
    });
    // Add draw interaction to map
    this.map.addInteraction(this.redlineDrawInteraction);

    // Create modify interaction
    this.modifyInteraction = new Modify({
      source: this.redlinePolygonSource,
      style: function () {
        return null;
      },
    });

    // Add modify interaction to map
    this.map.addInteraction(this.modifyInteraction);

    // Set style for redline polygon layer to match draw style
    this.redlinePolygonLayer.setStyle(polygonEditStyleFunction)

    // Set flag for context menu availability
    this.redlinePolygonEdit = true;

    // Add keystroke listener for enter/escape
    document.addEventListener('keydown', this.enterHandlerFn.bind(this), { signal: this.drawKeyEventAbortController.signal });
    document.addEventListener('keydown', this.escapeHandlerFn.bind(this), { signal: this.drawKeyEventAbortController.signal });
    document.addEventListener('keydown', this.backspaceHandlerFn.bind(this), { signal: this.drawKeyEventAbortController.signal });
    // Add listener for when drawing has finished
    this.redlineDrawInteraction.once('drawend', (event) => {
      this.finishEditingRedLine();
    });

    // Add listener for when drawing has been aborted
    this.redlineDrawInteraction.once('drawabort', (event) => {
      this.finishEditingRedLine();
    });

  }

  public finishEditingRedLine(): void {

    // Remove key listeners
    this.drawKeyEventAbortController.abort();
    this.drawKeyEventAbortController = new AbortController();

    // Finish drawings
    this.redlineDrawInteraction.finishDrawing();

    // Disable draw interaction
    this.map.removeInteraction(this.redlineDrawInteraction);

    // Disable modify interaction
    this.map.removeInteraction(this.modifyInteraction);

    // Set redline polygon layer style to default
    this.redlinePolygonLayer.setStyle(redlineStyle)

    // Set flag to disable context menu for polygon outside edit mode
    this.redlinePolygonEdit = false;

    // Dispatch event to notify listeners that editing has ended
    this.map.dispatchEvent("redline_editend");
  }

  private overlayIsTextAnnotation(overlay: Overlay): boolean {
    const overlayId = overlay.getId();
    return (typeof overlayId === 'string' && overlayId.startsWith('mat_'))
  }

  public clearTextAnnotations(): void {
    // Don't delete annotations in a forEach callback, mutations aren't handled
    // well. Instead, get a list of the overlays to be removed, then remove each one
    const textAnnotationOverlays = this.map.getOverlays().getArray().filter(this.overlayIsTextAnnotation);
    const len = textAnnotationOverlays.length;
    for (let i = 0; i < len; i++) {
      this.map.removeOverlay(textAnnotationOverlays[i]);
    }
  }

  private createOverlayElement(text: string, style: TextAnnotationStyle): HTMLDivElement {
    const element = document.createElement('div');
    element.innerText = text;
    const elementStyle = getStyleAttributeForStyle(style);
    Object.assign(element.style, elementStyle);
    return element;
  }

  public setTextAnnotations(annotations: any): void {
    this.clearTextAnnotations();

    annotations.forEach((annotation) => {
      const annotationStyleProperty = annotation.properties?.style || {};
      const style = { ...textAnnotationDefaultStyle, ...annotationStyleProperty };
      const overlay = new Overlay({
        id: annotation.id,
        element: this.createOverlayElement(annotation.properties.text, style),
        position: annotation.geometry.coordinates,
        positioning: 'center-center',
      })
      this.map.addOverlay(overlay);

      // Add annotation to vector source for syncing changes back to database
      const point = new Point(annotation.geometry.coordinates);
      const feature = new Feature(point);
      feature.setId(annotation.id);
      feature.set('text', annotation.properties.text);
      // If the annotation has a custom style, set that on the feature
      if (annotation.properties?.style) {
        feature.set('style', annotation.properties.style);
      }
      this.textAnnotationsSource.addFeature(feature);
    });
  }

  private editTextAnnotation(id: string): void {
    // If an annotation is already in edit mode, finish its edit, then mark this
    // one as being in edit mode
    if (this.editingTextAnnotation) {
      this.finishTextAnnotationEdit(this.editingTextAnnotation);
    }
    this.editingTextAnnotation = id;

    // Set content editable
    const element = this.map.getOverlayById(id).getElement();
    element.contentEditable = "true";
    element.classList.add('border', 'border-sf-orange', 'focus:outline', 'focus:outline-sf-orange', 'focus:border', 'focus:border-sf-orange');
    element.classList.remove('select-none');

    // Escape to complete the edit
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        this.finishTextAnnotationEdit(id);
      }
    }, { signal: this.textAnnotationEditEventAbortController.signal });

    // Click outside of overlay to complete the edit
    const mapContainerCollection = document.getElementsByClassName('map');
    if (mapContainerCollection.length) {
      const mapElement = mapContainerCollection[0];
      document.addEventListener('mousedown', (e) => {
        // If click was in the current overlay, do nothing. Otherwise, finish edit
        const mapViewportPosition = mapElement.getBoundingClientRect();
        const clickPositionInMap = [
          e.clientX - mapViewportPosition.x,
          e.clientY - mapViewportPosition.y
        ];
        const overlayIdOrNull = this.getTextAnnotationAtCursor(clickPositionInMap);
        if (!(overlayIdOrNull==id)) {
          e.preventDefault();
          this.finishTextAnnotationEdit(id);
        }
      }, { signal: this.textAnnotationEditEventAbortController.signal })
    }

    // Put focus in the new input element
    element.focus();
  }

  private finishTextAnnotationEdit(id: string): void {
    // Remove keypress event handlers
    this.textAnnotationEditEventAbortController.abort();
    this.textAnnotationEditEventAbortController = new AbortController();

    const overlay = this.map.getOverlayById(id);
    const element = overlay.getElement();
    element.contentEditable = "false";
    element.classList.remove('border', 'border-sf-orange', 'focus:outline', 'focus:outline-sf-orange', 'focus:border', 'focus:border-sf-orange');
    element.classList.add('select-none');
    const labelText = element.innerText;
    // Update VectorSource feature, which will trigger update for database
    const feature = this.textAnnotationsSource.getFeatureById(id);
    feature.set('text', labelText);

    // Reset annotation edit mode flag
    this.editingTextAnnotation = null;
  };

  public createTextAnnotation(coordinates: [number, number]): void {
    // Create a unique id for feature, using prefix mat (for "map annotation, text")
    const id = "mat_" + nanoid(16);
    const initialText = "";

    // Add feature to vector source to trigger update in database
    const point = new Point(coordinates);
    const feature = new Feature(point);
    feature.setId(id);
    feature.set('text', initialText);
    this.textAnnotationsSource.addFeature(feature);

    // Create the overlay on the map
    const element = this.createOverlayElement(initialText, textAnnotationDefaultStyle);
    const overlay = new Overlay({
        id: id,
        element: element,
        position: coordinates,
        positioning: "center-center",
    });
    this.map.addOverlay(overlay);

    // Now edit the new annotation
    this.editTextAnnotation(id);
  }

  private deleteTextAnnotation(id: string): void {
    // Remove overlay from map
    const overlay = this.map.getOverlayById(id);
    this.map.removeOverlay(overlay);

    // Remove annotation feature from vector source to trigger deletion from database
    const feature = this.textAnnotationsSource.getFeatureById(id);
    this.textAnnotationsSource.removeFeature(feature);
  }

  private setupTextAnnotationMouseEvents(): void {
    const mapContainerCollection = document.getElementsByClassName('map');
    // New Scheme map container has class "site-map" instead of "map", but
    // annotation drag events aren't needed, so don't set up for New Scheme map
    if (!mapContainerCollection.length) {
      return;
    }
    const mapElement = mapContainerCollection[0];

    // Mouse down event to start dragging if on an overlay
    mapElement.addEventListener('mousedown', (e) => {
      // Get click position relative to map element
      const mapViewportPosition = mapElement.getBoundingClientRect();
      const clickPositionInMap = [
        e.clientX - mapViewportPosition.x,
        e.clientY - mapViewportPosition.y,
      ];

      const overlayIdOrNull = this.getTextAnnotationAtCursor(clickPositionInMap);
      if (!overlayIdOrNull) {
        return;
      }

      this.draggedTextAnnotation = this.map.getOverlayById(overlayIdOrNull);

      // Calculate offset of click from anchor point, so new position is relative to mouse position
      const overlayPosition = this.map.getPixelFromCoordinate(this.draggedTextAnnotation.getPosition());
      this.draggedTextAnnotationOffset = {
        x: e.clientX - overlayPosition[0],
        y: e.clientY - overlayPosition[1],
      };

      // Reduce opacity of overlay element during drag effect
      const element = this.draggedTextAnnotation.getElement();
      element.classList.add('!opacity-60');
    });

    // Mouse move event, across document, not contained to element
    mapElement.addEventListener('mousemove', (e) => {
      if (!this.draggedTextAnnotation) {
        return;
      }
      const currentPixel = [
        e.clientX - this.draggedTextAnnotationOffset.x,
        e.clientY - this.draggedTextAnnotationOffset.y,
      ];
      const newPosition = this.map.getCoordinateFromPixel(currentPixel);
      this.draggedTextAnnotation.setPosition(newPosition);
    });

    // Stop movement when mouse released
    mapElement.addEventListener('mouseup', () => {
      if (!this.draggedTextAnnotation) {
        return;
      }

      // update feature in textAnnotationsSource to trigger db update
      const id = this.draggedTextAnnotation.getId();
      const feature = this.textAnnotationsSource.getFeatureById(id);
      const point = new Point(this.draggedTextAnnotation.getPosition());
      feature.setGeometry(point);

      // Unset drag values
      const element = this.draggedTextAnnotation.getElement();
      element.classList.remove('!opacity-60');
      this.draggedTextAnnotation = null;
      this.draggedTextAnnotationOffset = { x: 0, y: 0};
    });

    // Double click an annotation starts enables editing the text
    mapElement.addEventListener('dblclick', (e) => {
      // Get click position relative to map element
      const mapViewportPosition = mapElement.getBoundingClientRect();
      const clickPositionInMap = [
        e.clientX - mapViewportPosition.x,
        e.clientY - mapViewportPosition.y,
      ];

      const overlayIdOrNull = this.getTextAnnotationAtCursor(clickPositionInMap);
      if (overlayIdOrNull) {
        this.editTextAnnotation(overlayIdOrNull);
      }
    });
  }

  private getTextAnnotationAtCursor(pixel: [number, number]): string | null {
    // For each Overlay, if it has an id beginning with `mat_` (map annotation,
    // text), check if the position given by pixel lies within the overlay.
    //
    // If pixel is within an overlay, return the id for that overlay, else return null.
    const overlaysCollection = this.map.getOverlays();
    const len = overlaysCollection.getLength();

    for (let i = 0; i < len; i++) {
      const overlay = overlaysCollection.item(i);

      if (!this.overlayIsTextAnnotation(overlay)) {
        continue;
      }

      const overlayPositionPixel = this.map.getPixelFromCoordinate(overlay.getPosition())
      const overlayElement = overlay.getElement();
      const width = overlayElement.offsetWidth;
      const height = overlayElement.offsetHeight;

      // Assume overlay positioning is 'center-center'. This may be changed later.
      const pixelExtent = {
        xMin: overlayPositionPixel[0] - (width / 2),
        xMax: overlayPositionPixel[0] + (width / 2),
        yMin: overlayPositionPixel[1] - (height / 2),
        yMax: overlayPositionPixel[1] + (height / 2),
      };

      if (pixel[0] >= pixelExtent.xMin && pixel[0] <= pixelExtent.xMax && pixel[1] >= pixelExtent.yMin && pixel[1] <= pixelExtent.yMax) {
        return overlay.getId();
      }
    }

    return null;
  }

  public newTextAnnotationChoosePoint(): void {
    if (!this.textAnnotationDrawInteraction) {
      // Set up the draw interaction (only required on first use)
      this.textAnnotationDrawInteraction = new Draw({
        type: "Point",
        style: new Style({
          image: new CircleStyle({
            fill: new Fill({ color: "red" }),
            radius: 5,
          }),
        }),
        stopClick: true,
        maxPoints: 1,
      });

      this.textAnnotationDrawInteraction.on("drawend", (e) => {
        this.endTextAnnotationChoosePoint();

        // Create the annotation at the selected point
        this.createTextAnnotation(e.feature.getGeometry().getCoordinates());
      })

      // Add interaction to the map
      this.map.addInteraction(this.textAnnotationDrawInteraction);
    }

    // Set up key handlers - use escape or backspace to cancel point selection
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' || e.key === 'Backspace') {
        e.preventDefault();
        this.endTextAnnotationChoosePoint();
      }
    }, { signal: this.annotationDrawKeyEventAbortController.signal });

    this.textAnnotationDrawInteraction.setActive(true);
    this.annotationDrawingActive = 'text';
  }

  private endTextAnnotationChoosePoint(): void {
    this.textAnnotationDrawInteraction.finishDrawing();
    this.textAnnotationDrawInteraction.setActive(false);

    // Remove key handler
    this.annotationDrawKeyEventAbortController.abort()
    this.annotationDrawKeyEventAbortController = new AbortController();

    // Flag for UI changes
    this.annotationDrawingActive = null;
  }

  private styleTextAnnotation(id: string): void {
    // Ensure shape annotation style is not happening simultaneously
    this.finishStyleShapeAnnotation();

    this.triggerTextAnnotationStyleEditChangeEvent(id);

    // Clicking on map will end style edit on the current annotation
    const mapContainerCollection = document.getElementsByClassName('map');
    if (mapContainerCollection.length) {
      const mapElement = mapContainerCollection[0];
      mapElement.addEventListener('mousedown', (e) => {
        const mapViewportPosition = mapElement.getBoundingClientRect();
        const clickPositionInMap = [
          e.clientX - mapViewportPosition.x,
          e.clientY - mapViewportPosition.y
        ];
        const overlayIdOrNull = this.getTextAnnotationAtCursor(clickPositionInMap);
        // If click is not on the annotation being edited, end style editing
        if (!(overlayIdOrNull===id)) {
          e.preventDefault();
          this.finishStylingTextAnnotation();
        }
      }, { signal: this.textAnnotationStyleAbortController.signal });
    }
  }

  public finishStylingTextAnnotation(): void {
    // Remove edit click handler, if set
    this.textAnnotationStyleAbortController.abort();
    this.textAnnotationStyleAbortController = new AbortController();

    this.triggerTextAnnotationStyleEditChangeEvent(null);
  }

  private triggerTextAnnotationStyleEditChangeEvent(id: string | null): void {
    const event = new TextAnnotationStyleEditChangeEvent(id);
    this.map.dispatchEvent(event);
  }

  public setTextAnnotationStyle(id: string, newStyle: TextAnnotationStyle): void {
    // Set the style for the text annotation with id.

    // Update the Overlay displaying the annotation
    const overlay = this.map.getOverlayById(id);
    const element = overlay.getElement()
    const elementStyle = getStyleAttributeForStyle(newStyle)
    Object.assign(element.style, elementStyle);

    // Update the vector layer feature to trigger update to database
    const feature = this.textAnnotationsSource.getFeatureById(id);
    feature.set('style', newStyle);
  }

  public unsetTextAnnotationStyle(id: string): void {
    // Set overlay style to default
    const overlay = this.map.getOverlayById(id);
    const element = overlay.getElement();
    const defaultStyle = getStyleAttributeForStyle(textAnnotationDefaultStyle);
    Object.assign(element.style, defaultStyle);

    // Unset style in vector source feature, so that default values are used in future
    const feature = this.textAnnotationsSource.getFeatureById(id);
    feature.unset('style');
  }

  public clearShapeAnnotations(): void {
    this.shapeAnnotationsSource.clear();
  }

  public setShapeAnnotations(annotationCollection: any): void {
    this.clearShapeAnnotations();

    const geojson = new GeoJSON();
    const features = geojson.readFeatures(annotationCollection, {
      dataProjection: this.map.getView().getProjection(),
      featureProjection: this.map.getView().getProjection(),
    });

    this.shapeAnnotationsSource.addFeatures(features);
  }

  public createShapeAnnotation(type: shapeAnnotationType) {
    const geometryType: Record<shapeAnnotationType, string> = {
      [shapeAnnotationType.POLYGON]: 'Polygon',
      [shapeAnnotationType.LINE]: 'LineString',
      [shapeAnnotationType.ARROW]: 'LineString',
    };

    const drawingStyle: Record<shapeAnnotationType, (feature: Feature) => Style[]> = {
      [shapeAnnotationType.POLYGON]: shapeAnnotationDrawPolygonStyle,
      [shapeAnnotationType.LINE]: shapeAnnotationDrawLineStyle,
      [shapeAnnotationType.ARROW]: shapeAnnotationDrawLineStyle,
    };

    this.shapeAnnotationDrawInteraction = new Draw({
      type: geometryType[type],
      source: this.shapeAnnotationsSource,
      snapTolerance: 6,
      stopClick: true,
      style: drawingStyle[type],
    });

    // Key handlers - use escape or backspace to cancel point selection
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' || e.key === 'Backspace') {
        e.preventDefault();
        this.finishCreatingShapeAnnotation();
      }
    }, { signal: this.annotationDrawKeyEventAbortController.signal });

    // When draw completes, create an ID for the new annotation
    this.shapeAnnotationDrawInteraction.once("drawend", (e) => {
      // Create a unique id for the new annotation, with prefix 'mas' for "map annotation, shape"
      const id = "mas_" + nanoid(16);
      e.feature.setId(id);

      // If drawing an arrow, set a style with arrow head
      if (type == shapeAnnotationType.ARROW) {
        e.feature.set('style', shapeAnnotationArrowDefaultStyle);
      }

      this.finishCreatingShapeAnnotation();
    });

    this.map.addInteraction(this.shapeAnnotationDrawInteraction);
    this.shapeAnnotationDrawInteraction.setActive(true);

    this.annotationDrawingActive = type;
  }

  private finishCreatingShapeAnnotation() {
    this.shapeAnnotationDrawInteraction.finishDrawing();
    this.shapeAnnotationDrawInteraction.setActive(false);
    this.map.removeInteraction(this.shapeAnnotationDrawInteraction);

    // Remove key handler
    this.annotationDrawKeyEventAbortController.abort()
    this.annotationDrawKeyEventAbortController = new AbortController();

    this.annotationDrawingActive = null;
  }

  private triggerShapeAnnotationStyleEditChangeEvent(id: string | null): void {
    const event = new ShapeAnnotationStyleEditChangeEvent(id);
    this.map.dispatchEvent(event);
  }

  private handleClickDuringShapeAnnotationEdit(event): void {
    const clickPixel = event.pixel;
    let clickIsOnEditedAnnotation = false;
    this.map.forEachFeatureAtPixel(clickPixel,
      (feat) => {
        if (feat.getId() === this.shapeAnnotationStyleEditId) {
          clickIsOnEditedAnnotation = true;
          // Return true from callback to avoid testing remaining features
          return true;
        }
      },
      { layerFilter: (layer) => layer.get('name') === 'shape_annotations' }
    );

    // If user clicked on the annotation being edited, do nothing
    if (clickIsOnEditedAnnotation) {
      return;
    }

    // Click was not on edited annotation, so end editing and remove event handler
    this.finishStyleShapeAnnotation();
    this.map.un('singleclick', this.handleClickDuringShapeAnnotationEdit);
  }

  private styleShapeAnnotation(feature: Feature): void {
    // Ensure text annotation edit not happening at the same time
    this.finishStylingTextAnnotation();
    // If an annotation is currently selected for modification, deselect it
    // before opening styles. This ensures that the selected style is not
    // overriding the feature's normal style.
    this.unselectShapeAnnotations();

    const featId = feature.getId();

    // Click in map outside of current annotation to finish style editing
    this.shapeAnnotationStyleEditId = featId;
    this.map.on('singleclick', this.handleClickDuringShapeAnnotationEdit.bind(this));

    this.triggerShapeAnnotationStyleEditChangeEvent(featId);
  }

  public finishStyleShapeAnnotation(): void {
    this.triggerShapeAnnotationStyleEditChangeEvent(null);
  }

  public setShapeAnnotationStyle(id: string, style: ShapeAnnotationLineStyle | ShapeAnnotationPolygonStyle): void {
    const feature = this.shapeAnnotationsSource.getFeatureById(id);
    feature.set('style', style);
    feature.changed();
  }

  public unsetShapeAnnotationStyle(id: string): void {
    const feature = this.shapeAnnotationsSource.getFeatureById(id);
    feature.unset('style');
    feature.changed();
  }

  private setupShapeAnnotationInteractions(): void {
    this.shapeAnnotationTranslateInteraction = new Translate({
      layers: (layer) => layer.get('name') === 'shape_annotations',
    });
    this.map.addInteraction(this.shapeAnnotationTranslateInteraction);

    // Double click to select a feature, enabling modify interaction for that feature
    this.shapeAnnotationSelectInteraction = new Select({
      layers: (layer) => layer.get('name') === 'shape_annotations',
      condition: doubleClick,
      toggleCondition: doubleClick,
      style: shapeAnnotationSelectedFeatureStyle,
    })
    this.map.addInteraction(this.shapeAnnotationSelectInteraction);

    // Ensure shape isn't being style edited when selected
    this.shapeAnnotationSelectInteraction.on('select', this.finishStyleShapeAnnotation.bind(this));

    // Click on the map, off the feature, to deselect any selected elements
    this.map.on('singleclick', this.unselectShapeAnnotations.bind(this));

    this.shapeAnnotationModifyInteraction = new Modify({
      features: this.shapeAnnotationSelectInteraction.getFeatures(),
      style: () => null,
    });
    this.map.addInteraction(this.shapeAnnotationModifyInteraction);
  }

  private unselectShapeAnnotations(): void {
    const selectedFeatures = this.shapeAnnotationSelectInteraction.getFeatures();
      if (selectedFeatures.getLength()) {
        selectedFeatures.clear();

        // Reset modify interaction (occasionally persists on previously selected features
        this.shapeAnnotationModifyInteraction.setActive(false);
        this.map.removeInteraction(this.shapeAnnotationModifyInteraction);
        this.shapeAnnotationModifyInteraction = new Modify({
          features: this.shapeAnnotationSelectInteraction.getFeatures(),
          style: () => null,
        });
        this.map.addInteraction(this.shapeAnnotationModifyInteraction);
      }
  }
}
