/**
 * @privateRemarks
 * This file was generated by the FormKit CLI and should not be manually
 * edited unless you’d like to "eject" from the CLI’s ability to update it.
 *
 * @checksum - 69a53592644d11129ef8b91418bbfb772dc9c9ef6bfff7e366564ce85ff42cdd
 * @variables - radius=rounded,accentColor=orange,colorTemperature=gray,spacing=2,scale=base,inputShadow=shadow,baseColorShade=600,borderShadeLightMode=400,borderShadeDarkMode=500,inputMaxWidth=max-w-none,tagRadius=rounded,swatchRadius=rounded
 * @theme - regenesis
 **/

/**
 * This is the theme function itself, it should be imported and used as the
 * config.rootClasses function. For example:
 *
 * ```js
 * import { theme } from './formkit.theme'
 * import { defineFormKitConfig } from '@formkit/vue'
 *
 * export default defineFormKitConfig({
 *   config: {
 *     rootClasses: theme
 *   }
 * })
 * ```
 **/
export function rootClasses(sectionName, node) {
    const key = `${node.props.type}__${sectionName}`;
    const semanticKey = `formkit-${sectionName}`;
    const familyKey = node.props.family ? `family:${node.props.family}__${sectionName}` : "";
    const memoKey = `${key}__${familyKey}`;
    if (!(memoKey in classes)) {
        const sectionClasses = classes[key] ?? globals[sectionName] ?? {};
        sectionClasses[semanticKey] = true;
        if (familyKey in classes) {
            classes[memoKey] = { ...classes[familyKey], ...sectionClasses };
        } else {
            classes[memoKey] = sectionClasses;
        }
    }
    return classes[memoKey] ?? { [semanticKey]: true };
}

/**
 * These classes have already been merged with globals using tailwind-merge
 * and are ready to be used directly in the theme.
 **/
const classes = {
    "family:button__wrapper": {
        "group-data-[disabled=true]:grayscale": true,
    },
    "family:button__input": {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "text-white": true,
        uppercase: true,
        rounded: true,
        "outline-none": true,
        flex: true,
        "!text-sm": true,
        "px-7": true,
        "py-3": true,
        "items-center": true,
        "mb-1.5": true,
        "text-sm": true,
        "ring-offset-2": true,
        "ring-orange-500": true,
        "focus-visible:ring-2": true,
        "group-data-[disabled]:!cursor-not-allowed": true,
        shadow: true,
        "group-data-[prefix-icon]:pl-5": true,
        "group-data-[suffix-icon]:pr-5": true,
        border: true,
        "border-orange-600": true,
        "group-[]/repeater:shadow-sm": true,
        "group-[]/multistep:shadow-sm": true,
        "dark:border-orange-500": true,
    },
    "family:box__wrapper": {
        "inline-flex": true,
        "items-center": true,
        "mb-1": true,
        "group-data-[multiple]:mb-0": true,
    },
    "family:box__legend": {
        block: true,
        "text-gray-700": true,
        "text-sm": true,
        "font-bold": true,
        "dark:text-gray-300": true,
        "mb-2": true,
    },
    "family:box__input": {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        peer: true,
        "pointer-events-none": true,
        absolute: true,
        "h-0": true,
        "w-0": true,
        "overflow-hidden": true,
        "opacity-0": true,
    },
    "family:box__decorator": {
        "mr-1.5": true,
        "bg-white": true,
        "ring-orange-500": true,
        "peer-checked:border-orange-600": true,
        relative: true,
        block: true,
        "text-lg": true,
        "w-[1em]": true,
        "aspect-[1/1]": true,
        border: true,
        "border-gray-400": true,
        "text-transparent": true,
        "peer-checked:bg-orange-50": true,
        "peer-checked:text-orange-600": true,
        "peer-focus-visible:ring-2": true,
        "peer-focus-visible:ring-offset-1": true,
        "select-none": true,
        "group-data-[disabled]:!cursor-not-allowed": true,
        "peer-disabled:bg-gray-100": true,
        "group-data-[disabled]:grayscale": true,
        shadow: true,
        "peer-disabled:cursor-not-allowed": true,
        "group-[]/repeater:shadow-none": true,
        "group-[]/multistep:shadow-none": true,
        "dark:border-gray-500": true,
        "dark:bg-transparent": true,
        "dark:ring-offset-orange-500": true,
        "dark:peer-focus-visible:ring-1": true,
        "dark:peer-disabled:bg-gray-600/50": true,
        "dark:peer-checked:bg-orange-900": true,
        "dark:peer-checked:text-orange-400": true,
    },
    "family:box__decoratorIcon": {
        absolute: true,
        "left-1/2": true,
        "top-1/2": true,
        flex: true,
        "h-full": true,
        "w-full": true,
        "-translate-x-1/2": true,
        "-translate-y-1/2": true,
    },
    "family:box__option": {
        "mb-1.5": true,
        "last:mb-0": true,
        "data-[disabled]:opacity-50": true,
        "data-[disabled]:select-none": true,
        "group-data-[disabled]:data-[disabled]:opacity-100": true,
    },
    "family:box__label": {
        block: true,
        "text-gray-700": true,
        "text-sm": true,
        "font-bold": true,
        "mb-1": true,
        "!mb-0": true,
        "!font-normal": true,
        "!text-sm": true,
        "dark:text-gray-300": true,
    },
    "family:box__optionHelp": {
        "text-gray-500": true,
        "text-xs": true,
        "-mt-1": true,
        "mb-1.5": true,
        "ml-[min(2em,1.7rem)]": true,
        relative: true,
        "left-px": true,
    },
    "family:box__help": {
        "text-gray-500": true,
        "text-xs": true,
        "dark:text-gray-400": true,
        "mb-1": true,
        "group-data-[multiple]:mb-2": true,
        "group-data-[multiple]:-mt-1.5": true,
    },
    "family:text__wrapper": {
        flex: true,
        "flex-col": true,
        "items-start": true,
        "justify-start": true,
        "mb-1.5": true,
        "last:mb-0": true,
    },
    "family:text__label": {
        block: true,
        "text-gray-700": true,
        "text-sm": true,
        "font-bold": true,
        "dark:text-gray-300": true,
        "!inline-flex": true,
        "mb-1": true,
    },
    "family:text__inner": {
        "text-base": true,
        flex: true,
        "items-center": true,
        "w-full": true,
        "py-2": true,
        "px-3": true,
        rounded: true,
        border: true,
        "border-gray-400": true,
        "bg-white": true,
        "focus-within:ring-1": true,
        "focus-within:!ring-orange-500": true,
        "focus-within:!border-orange-500": true,
        "group-data-[invalid]:border-red-500": true,
        "group-data-[invalid]:ring-1": true,
        "group-data-[invalid]:ring-red-500": true,
        "group-data-[disabled]:bg-gray-100": true,
        "group-data-[disabled]:!cursor-not-allowed": true,
        shadow: true,
        "group-[]/repeater:shadow-none": true,
        "group-[]/multistep:shadow-none": true,
        "dark:bg-transparent": true,
        "dark:border-gray-500": true,
        "dark:group-data-[disabled]:bg-gray-800/5": true,
        "dark:group-data-[invalid]:border-red-500": true,
        "dark:group-data-[invalid]:ring-red-500": true,
    },
    "family:text__input": {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "text-base": true,
        "text-gray-700": true,
        "min-w-0": true,
        "min-h-[1.5em]": true,
        grow: true,
        "outline-none": true,
        "bg-transparent": true,
        "selection:bg-orange-100": true,
        "placeholder:text-gray-400": true,
        "group-data-[disabled]:!cursor-not-allowed": true,
        "dark:placeholder-gray-400/50": true,
        "dark:text-gray-300": true,
        "border-none": true,
        "p-0": true,
        "focus:ring-0": true,
    },
    "family:text__prefixIcon": {
        flex: true,
        "items-center": true,
        "-ml-1": true,
        "mr-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "text-gray-600": true,
        "dark:text-gray-300": true,
    },
    "family:text__suffixIcon": {
        flex: true,
        "items-center": true,
        "-mr-1": true,
        "ml-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "text-gray-600": true,
        "dark:text-gray-300": true,
    },
    "family:dropdown__wrapper": {
        "mb-1.5": true,
    },
    "family:dropdown__inner": {
        relative: true,
        flex: true,
        "items-center": true,
        "bg-white": true,
        border: true,
        "border-gray-400": true,
        rounded: true,
        "group-data-[is-multiline]:!rounded": true,
        "focus-within:ring-1": true,
        "focus-within:!ring-orange-500": true,
        "focus-within:!border-orange-500": true,
        "group-data-[invalid]:border-red-500": true,
        "group-data-[invalid]:ring-1": true,
        "group-data-[invalid]:ring-red-500": true,
        "group-data-[disabled]:bg-gray-100": true,
        "group-data-[disabled]:!cursor-not-allowed": true,
        shadow: true,
        "group-[]/repeater:shadow-none": true,
        "group-[]/multistep:shadow-none": true,
        "dark:bg-transparent": true,
        "dark:border-gray-500": true,
        "dark:group-data-[disabled]:bg-gray-700/40": true,
        "dark:group-data-[invalid]:border-red-500": true,
        "dark:group-data-[invalid]:ring-red-500": true,
    },
    "family:dropdown__input": {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        grow: true,
        "p-2": true,
        "pr-0": true,
        "pl-3": true,
        "text-base": true,
        "text-gray-700": true,
        "text-ellipsis": true,
        "min-w-0": true,
        "outline-none": true,
        "bg-transparent": true,
        "group-data-[disabled]:!cursor-not-allowed": true,
        "group-data-[prefix-icon]:!pl-0": true,
        "group-data-[suffix-icon]:!pr-0": true,
        "placeholder:text-gray-400": true,
        "selection:bg-orange-100": true,
        "dark:placeholder:text-gray-500": true,
        "dark:text-gray-300": true,
        "border-none": true,
        "focus:ring-0": true,
        "bg-none": true,
    },
    "family:dropdown__listboxButton": {
        "w-[2.5em]": true,
        "self-stretch": true,
        "text-base": true,
        flex: true,
        "items-center": true,
        "text-gray-700": true,
        "z-10": true,
        "dark:text-gray-300": true,
        "data-[disabled]:cursor-not-allowed": true,
    },
    "family:dropdown__removeSelection": {
        "w-[2.5em]": true,
        "self-stretch": true,
        "text-base": true,
        flex: true,
        "items-center": true,
        "text-gray-700": true,
        "hover:text-red-400": true,
        "z-10": true,
        "dark:text-gray-300": true,
    },
    "family:dropdown__controlLabel": {
        absolute: true,
        "opacity-0": true,
        "pointer-events-none": true,
        "text-[0px]": true,
    },
    "family:dropdown__selectIcon": {
        "text-base": true,
        "inline-flex": true,
        "justify-center": true,
        "w-[2.5em]": true,
        relative: true,
        "my-auto": true,
        "[&>svg]:w-[1em]": true,
        "[&>svg]:mx-auto": true,
    },
    "family:dropdown__closeIcon": {
        "text-base": true,
        "w-[0.75em]": true,
        relative: true,
        "m-auto": true,
    },
    "family:dropdown__prefixIcon": {
        flex: true,
        "items-center": true,
        "-ml-1": true,
        "mr-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "!ml-2": true,
        "!mr-0": true,
        "text-gray-600": true,
        "dark:text-gray-300": true,
    },
    "family:dropdown__suffixIcon": {
        flex: true,
        "items-center": true,
        "-mr-1": true,
        "ml-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "!mr-2": true,
        "!ml-0": true,
        "text-gray-600": true,
        "dark:text-gray-300": true,
    },
    "family:dropdown__dropdownWrapper": {
        rounded: true,
        "shadow-lg": true,
        "mt-1": true,
        "overflow-clip": true,
        "empty:hidden": true,
        border: true,
        "border-gray-300": true,
        "dark:border-gray-600": true,
        "group-data-[expanded]:opacity-100": true,
        "group-data-[overscroll]:m-0": true,
        "group-data-[overscroll]:shadow-none": true,
        "group-data-[overscroll]:border-none": true,
    },
    "family:dropdown__listitemGroup": {
        "group/optgroup": true,
        "last:pb-0": true,
        "border-t": true,
        "border-b": true,
        "-mb-px": true,
        "border-gray-300": true,
        "dark:border-gray-600": true,
    },
    "family:dropdown__groupLabel": {
        block: true,
        "pt-1.5": true,
        "pb-1": true,
        "px-2.5": true,
        "font-bold": true,
        "pointer-events-none": true,
        "text-gray-600": true,
        "dark:text-gray-300": true,
    },
    "family:dropdown__listbox": {
        "bg-white": true,
        rounded: true,
        "empty:hidden": true,
        "dark:bg-gray-800": true,
        "group-data-[overscroll]:shadow-lg": true,
        "group-data-[overscroll]:border": true,
        "group-data-[overscroll]:border-gray-300": true,
        "group-data-[overscroll]:dark:border-gray-600": true,
    },
    "family:dropdown__listitem": {
        relative: true,
        flex: true,
        "items-center": true,
        "px-2": true,
        "py-1.5": true,
        "first:pt-2": true,
        "last:pb-2": true,
        "text-gray-700": true,
        "text-base": true,
        "data-[is-active]:bg-orange-100": true,
        "dark:text-gray-200": true,
        "dark:data-[is-active]:text-gray-700": true,
        "before:content-['']": true,
        "before:absolute": true,
        "before:inset-0": true,
        "data-[is-active]:first:before:rounded": true,
        "data-[is-active]:first:before:rounded-b-none": true,
        "data-[is-active]:last:before:rounded": true,
        "data-[is-active]:last:before:rounded-t-none": true,
        "data-[is-active]:first:last:before:rounded": true,
        "data-[is-active]:before:ring-1": true,
        "data-[is-active]:before:ring-orange-500": true,
        "data-[is-active]:before:ring-inset": true,
        "data-[is-active]:before:ring-offset-orange-100": true,
        "group-[]/optgroup:first:before:!rounded-none": true,
        "group-[]/optgroup:last:before:!rounded-none": true,
    },
    "family:dropdown__selectedIcon": {
        flex: true,
        absolute: true,
        "items-center": true,
        "text-orange-600": true,
        "left-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
    },
    "family:dropdown__option": {
        "ml-[1.5em]": true,
    },
    "family:dropdown__loadMore": {
        "data-[is-active]:bg-orange-100": true,
    },
    "family:dropdown__loadMoreInner": {
        flex: true,
        "text-sm": true,
        "text-gray-500": true,
        "p-2": true,
        "items-center": true,
        "justify-center": true,
        "[&>span]:mr-2": true,
        "cursor-pointer": true,
        "dark:text-gray-200": true,
        "dark:hover:text-orange-500": true,
    },
    "family:dropdown__selectionWrapper": {
        grow: true,
        flex: true,
        "items-center": true,
        "text-gray-700": true,
    },
    "family:dropdown__selection": {
        grow: true,
        "text-gray-700": true,
        "group-data-[multiple]:p-2": true,
        "dark:text-gray-300": true,
    },
    "family:dropdown__tagsWrapper": {
        "w-full": true,
    },
    "family:dropdown__tagWrapper": {
        "group/tag": true,
        rounded: true,
        "mr-1": true,
        "mb-1": true,
        "outline-none": true,
        "data-[active-selection=true]:ring-2": true,
        "data-[active-selection=true]:ring-orange-500": true,
    },
    "family:dropdown__tags": {
        "inline-flex": true,
        "flex-wrap": true,
        "items-center": true,
        "w-full": true,
        "-mb-1": true,
        "empty:mb-0": true,
    },
    "family:dropdown__tag": {
        flex: true,
        "items-center": true,
        "cursor-default": true,
        rounded: true,
        "text-sm": true,
        "px-1.5": true,
        "py-0.5": true,
        "bg-orange-600": true,
        "text-white": true,
        "[&>[type=button]]:!w-[0.5em]": true,
        "[&>[type=button]]:aspect-[1/1]": true,
        "[&>[type=button]]:!text-inherit": true,
        "[&>[type=button]]:cursor-pointer": true,
        "group-data-[active-selection=true]/tag:bg-orange-400": true,
        "group-data-[active-selection=true]/tag:text-gray-700": true,
    },
    "family:dropdown__tagLabel": {
        "mr-1": true,
    },
    "family:dropdown__emptyMessage": {
        flex: true,
        "items-center": true,
        "px-2": true,
        "py-1.5": true,
        "first:pt-2": true,
        "last:pb-2": true,
        "text-gray-700": true,
        "text-sm": true,
        "aria-selected:text-white": true,
        "aria-selected:bg-orange-600": true,
    },
    button__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "bg-orange-50": true,
        "hover:bg-orange-100": true,
        "dark:text-orange-500": true,
        "dark:bg-transparent": true,
        "dark:hover:bg-orange-50/5": true,
    },
    checkbox__decorator: {
        rounded: true,
    },
    checkbox__decoratorIcon: {
        "max-w-[66.66%]": true,
    },
    color__inner: {
        "!w-auto": true,
        "!p-1.5": true,
        "!inline-flex": true,
        "group-data-[prefix-icon]:border": true,
        "group-data-[prefix-icon]:border-gray-400": true,
        "group-data-[suffix-icon]:border": true,
        "group-data-[suffix-icon]:border-gray-400": true,
        "dark:group-data-[prefix-icon]:border-gray-500": true,
        "dark:group-data-[suffix-icon]:border-gray-500": true,
    },
    color__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "!w-14": true,
        "bg-transparent": true,
        "cursor-pointer": true,
        rounded: true,
        "overflow-clip": true,
        "[&::-webkit-color-swatch-wrapper]:p-0": true,
        "[&::-webkit-color-swatch]:border-none": true,
        "[&::-moz-color-swatch]:border-none": true,
        "group-data-[prefix-icon]:mx-2": true,
        "group-data-[suffix-icon]:mx-2": true,
    },
    color__prefixIcon: {
        flex: true,
        "items-center": true,
        "-ml-1": true,
        "mr-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "group-data-[prefix-icon]:m-1.5": true,
        "group-data-[prefix-icon]:mr-0": true,
    },
    color__suffixIcon: {
        flex: true,
        "items-center": true,
        "-mr-1": true,
        "ml-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "group-data-[suffix-icon]:m-1.5": true,
        "group-data-[prefix-icon]:ml-0": true,
    },
    date__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "focus:[&::-webkit-datetime-edit-day-field]:bg-orange-100": true,
        "focus:[&::-webkit-datetime-edit-month-field]:bg-orange-100": true,
        "focus:[&::-webkit-datetime-edit-year-field]:bg-orange-100": true,
    },
    "datetime-local__input": {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "focus:[&::-webkit-datetime-edit-day-field]:bg-orange-100": true,
        "focus:[&::-webkit-datetime-edit-month-field]:bg-orange-100": true,
        "focus:[&::-webkit-datetime-edit-year-field]:bg-orange-100": true,
        "focus:[&::-webkit-datetime-edit-hour-field]:bg-orange-100": true,
        "focus:[&::-webkit-datetime-edit-minute-field]:bg-orange-100": true,
        "focus:[&::-webkit-datetime-edit-ampm-field]:bg-orange-100": true,
    },
    file__fileList: {
        "group/list": true,
        peer: true,
        "w-full": true,
        "min-w-0": true,
        "data-[has-multiple]:mb-[1.25em]": true,
    },
    file__fileItemIcon: {
        "h-[1em]": true,
        "w-[1em]": true,
        "mr-2": true,
        "shrink-0": true,
    },
    file__fileItem: {
        flex: true,
        "min-w-0": true,
        "items-center": true,
        "text-gray-700": true,
        "mb-1.5": true,
        "last:mb-0": true,
        "dark:text-gray-300": true,
    },
    file__fileName: {
        truncate: true,
        "min-w-0": true,
        "w-full": true,
        shrink: true,
        "leading-5": true,
        "group-data-[has-multiple]/list:text-sm": true,
    },
    file__fileRemove: {
        "right-2": true,
        "ring-orange-500": true,
        rounded: true,
        "z-20": true,
        flex: true,
        "appearance-none": true,
        "items-center": true,
        "text-[0px]": true,
        "outline-none": true,
        "hover:!text-red-500": true,
        "focus-visible:ring-2": true,
        "group-data-[disabled]:pointer-events-none": true,
        "group-data-[disabled]:!text-gray-500": true,
        "peer-data-[has-multiple]:absolute": true,
        "peer-data-[has-multiple]:bottom-[max(0.5em,8px)]": true,
        "peer-data-[has-multiple]:left-3": true,
        "peer-data-[has-multiple]:text-orange-600": true,
        "peer-data-[has-multiple]:text-xs": true,
        "peer-data-[has-multiple]:whitespace-nowrap": true,
        "group-data-[prefix-icon]:peer-data-[has-multiple]:left-2": true,
        "dark:hover:!text-red-400": true,
    },
    file__fileRemoveIcon: {
        block: true,
        "text-base": true,
        "w-[0.75em]": true,
        relative: true,
        "z-10": true,
    },
    file__inner: {
        relative: true,
        "cursor-pointer": true,
        "group-data-[has-multiple]:rounded": true,
    },
    file__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "cursor-pointer": true,
        "text-transparent": true,
        absolute: true,
        "inset-0": true,
        "opacity-0": true,
        "z-10": true,
        "file:pointer-events-none": true,
        "file:w-0": true,
        "file:h-0": true,
        "file:overflow-hidden": true,
    },
    file__noFiles: {
        flex: true,
        "w-full": true,
        "items-center": true,
        "text-gray-400": true,
        "dark:text-gray-500": true,
    },
    file__noFilesIcon: {
        "w-[1em]": true,
        "mr-2": true,
    },
    form__form: {
        "group/form": true,
    },
    form__actions: {
        "": true,
    },
    form__summaryInner: {
        "group/summary": true,
        border: true,
        "border-gray-400": true,
        "bg-white": true,
        rounded: true,
        "py-2": true,
        "px-3": true,
        shadow: true,
        "dark:bg-transparent": true,
        "dark:border-gray-500": true,
    },
    form__summaryHeader: {
        "text-lg": true,
        "text-gray-700": true,
        "font-bold": true,
        "mb-2": true,
        "dark:text-gray-300": true,
    },
    form__messages: {
        "": true,
    },
    form__message: {
        "text-red-600": true,
        "mb-1.5": true,
        "text-xs": true,
        "dark:text-red-400": true,
        "group-[]/summary:text-sm": true,
    },
    form__messageLink: {
        "group-[]/summary:outline-none": true,
        "group-[]/summary:focus-visible:ring-2": true,
        "group-[]/summary:ring-orange-600": true,
    },
    month__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "focus:[&::-webkit-datetime-edit-month-field]:bg-orange-100": true,
        "focus:[&::-webkit-datetime-edit-year-field]:bg-orange-100": true,
    },
    radio__decorator: {
        "rounded-full": true,
    },
    radio__decoratorIcon: {
        "max-w-[50%]": true,
    },
    range__inner: {
        relative: true,
        "!border-none": true,
        "!ring-0": true,
        "!px-0": true,
        "!bg-transparent": true,
        "!shadow-none": true,
    },
    range__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "group/input": true,
        "cursor-pointer": true,
        "[&::-webkit-slider-runnable-track]:bg-gray-400/50": true,
        "[&::-webkit-slider-runnable-track]:h-[0.25em]": true,
        "[&::-webkit-slider-runnable-track]:rounded": true,
        "dark:[&::-webkit-slider-runnable-track]:bg-gray-500/50": true,
        "[&::-webkit-slider-thumb]:appearance-none": true,
        "[&::-webkit-slider-thumb]:w-[0.85em]": true,
        "[&::-webkit-slider-thumb]:aspect-[1/1]": true,
        "[&::-webkit-slider-thumb]:bg-orange-600": true,
        "[&::-webkit-slider-thumb]:rounded-full": true,
        "[&::-webkit-slider-thumb]:relative": true,
        "[&::-webkit-slider-thumb]:top-1/2": true,
        "[&::-webkit-slider-thumb]:-translate-y-1/2": true,
        "[&::-webkit-slider-thumb]:group-data-[disabled]:bg-gray-500": true,
        "[&::-webkit-slider-thumb]:group-data-[disabled]:!ring-gray-300": true,
        "[&::-webkit-slider-thumb]:focus-visible:ring-2": true,
        "[&::-webkit-slider-thumb]:focus:!ring-orange-500": true,
        "[&::-webkit-slider-thumb]:focus:ring-offset-2": true,
        "[&::-webkit-slider-thumb]:shadow": true,
        "dark:[&::-webkit-slider-thumb]:group-data-[disabled]:!ring-gray-600": true,
        "dark:[&::-webkit-slider-thumb]:focus:ring-offset-gray-700": true,
        "[&::-moz-range-track]:bg-gray-400/50": true,
        "[&::-moz-range-track]:h-[0.25]": true,
        "[&::-moz-range-track]:rounded": true,
        "dark:[&::-moz-range-track]:bg-gray-500/50": true,
        "[&::-moz-range-thumb]:appearance-none": true,
        "[&::-moz-range-thumb]:border-none": true,
        "[&::-moz-range-thumb]:w-[0.85em]": true,
        "[&::-moz-range-thumb]:h-[0.85em]": true,
        "[&::-moz-range-thumb]:bg-orange-600": true,
        "[&::-moz-range-thumb]:rounded-full": true,
        "[&::-moz-range-thumb]:group-data-[disabled]:bg-gray-500": true,
        "[&::-moz-range-thumb]:group-data-[disabled]:!ring-gray-300": true,
        "[&::-moz-range-thumb]:focus-visible:ring-2": true,
        "[&::-moz-range-thumb]:focus:!ring-orange-500": true,
        "[&::-moz-range-thumb]:focus:ring-offset-2": true,
        "[&::-moz-range-thumb]:shadow": true,
        "dark:[&::-moz-range-thumb]:group-data-[disabled]:!ring-gray-500": true,
        "dark:[&::-moz-range-thumb]:focus:ring-offset-gray-700": true,
    },
    select__wrapper: {
        "mb-1.5": true,
    },
    select__inner: {
        relative: true,
        flex: true,
        "items-center": true,
        "bg-white": true,
        border: true,
        "border-gray-400": true,
        rounded: true,
        "focus-within:ring-1": true,
        "focus-within:!ring-orange-500": true,
        "focus-within:!border-orange-500": true,
        "group-data-[invalid]:border-red-500": true,
        "group-data-[invalid]:ring-1": true,
        "group-data-[invalid]:ring-red-500": true,
        "group-data-[disabled]:bg-gray-100": true,
        "group-data-[disabled]:!cursor-not-allowed": true,
        shadow: true,
        "group-[]/repeater:shadow-none": true,
        "group-[]/multistep:shadow-none": true,
        "group-data-[multiple]:rounded": true,
        "dark:bg-transparent": true,
        "dark:border-gray-500": true,
        "dark:group-data-[disabled]:bg-gray-800/5": true,
        "dark:group-data-[invalid]:border-red-500": true,
        "dark:group-data-[invalid]:ring-red-500": true,
    },
    select__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        grow: true,
        "p-2": true,
        "py-2": true,
        "px-3": true,
        "pr-[2em]": true,
        "text-base": true,
        "text-gray-700": true,
        "text-ellipsis": true,
        "min-w-0": true,
        "outline-none": true,
        "bg-transparent": true,
        "group-data-[disabled]:!cursor-not-allowed": true,
        "group-data-[prefix-icon]:!pl-0": true,
        "group-data-[suffix-icon]:!pr-0": true,
        "data-[placeholder]:text-gray-400": true,
        "group-data-[multiple]:!p-0": true,
        "selection:bg-orange-100": true,
        "dark:data-[placeholder]:text-gray-400/50": true,
        "dark:text-gray-300": true,
        "border-none": true,
        "focus:ring-0": true,
        "bg-none": true,
    },
    select__selectIcon: {
        absolute: true,
        "w-[1em]": true,
        "text-gray-700": true,
        "pointer-events-none": true,
        "right-2": true,
        "group-data-[suffix-icon]:mr-[1.5em]": true,
        "dark:text-gray-300": true,
    },
    select__optGroup: {
        "bg-white": true,
        "text-gray-700": true,
        "group/optgroup": true,
        "group-data-[multiple]:px-1.5": true,
        "pt-1.5": true,
        "font-bold": true,
        "text-sm": true,
        "dark:bg-gray-800": true,
        "dark:text-gray-300": true,
    },
    select__option: {
        "bg-white": true,
        "text-gray-700": true,
        "group-data-[disabled]:opacity-50": true,
        "group-data-[disabled]:select-none": true,
        "group-data-[multiple]:checked:bg-orange-100": true,
        "group-data-[multiple]:focus:bg-orange-100": true,
        "group-data-[multiple]:text-sm": true,
        "group-data-[multiple]:outline-none": true,
        "group-data-[multiple]:border-none": true,
        "group-data-[multiple]:py-1.5": true,
        "group-data-[multiple]:px-2": true,
        "dark:bg-gray-800": true,
        "dark:text-gray-300": true,
        "dark:group-data-[multiple]:focus:bg-orange-800": true,
        "dark:group-data-[multiple]:checked:bg-orange-800": true,
    },
    select__prefixIcon: {
        flex: true,
        "items-center": true,
        "mr-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "ml-2": true,
        "text-gray-600": true,
        "dark:text-gray-300": true,
    },
    select__suffixIcon: {
        flex: true,
        "items-center": true,
        "ml-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "mr-2": true,
        "text-gray-600": true,
        "dark:text-gray-300": true,
    },
    submit__outer: {
        group: true,
        "max-w-none": true,
        "min-w-0": true,
        grow: true,
        "mb-4": true,
        "data-[disabled]:select-none": true,
        "text-base": true,
        "data-[disabled]:opacity-100": true,
    },
    submit__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "bg-orange-600": true,
        "!text-white": true,
        "active:text-orange-100": true,
        "active:bg-orange-700": true,
        "hover:bg-orange-700": true,
        "disabled:border-gray-400": true,
        "disabled:bg-gray-400": true,
        "disabled:text-gray-100": true,
        "group-data-[disabled]:!cursor-not-allowed": true,
        "dark:disabled:border-gray-100": true,
        "dark:disabled:bg-gray-500": true,
        "dark:disabled:text-gray-200": true,
        "dark:text-white": true,
        "dark:ring-offset-orange-500": true,
        "before:transition-all": true,
    },
    submit__prefixIcon: {
        flex: true,
        "items-center": true,
        "-ml-1": true,
        "mr-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "text-gray-100": true,
    },
    submit__suffixIcon: {
        flex: true,
        "items-center": true,
        "-mr-1": true,
        "ml-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "text-gray-100": true,
    },
    textarea__inner: {
        flex: true,
        "items-center": true,
        "mb-1.5": true,
        "bg-white": true,
        border: true,
        "border-gray-400": true,
        rounded: true,
        "focus-within:ring-1": true,
        "focus-within:!ring-orange-500": true,
        "focus-within:!border-orange-500": true,
        "group-data-[invalid]:border-red-500": true,
        "group-data-[invalid]:ring-1": true,
        "group-data-[invalid]:ring-red-500": true,
        "group-data-[disabled]:bg-gray-100": true,
        shadow: true,
        "group-[]/repeater:shadow-none": true,
        "group-[]/multistep:shadow-none": true,
        "dark:border-gray-500": true,
        "dark:group-data-[disabled]:bg-gray-800/5": true,
        "dark:group-data-[invalid]:border-red-500": true,
        "dark:group-data-[invalid]:ring-red-500": true,
        "dark:bg-transparent": true,
    },
    textarea__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "text-base": true,
        "h-24": true,
        "text-gray-700": true,
        "min-w-0": true,
        grow: true,
        shrink: true,
        "!py-2": true,
        "!px-3": true,
        "outline-none": true,
        "bg-transparent": true,
        "selection:bg-orange-100": true,
        "placeholder:text-gray-400": true,
        "group-data-[disabled]:!cursor-not-allowed": true,
        "dark:placeholder-gray-400/50": true,
        "dark:text-gray-300": true,
        "p-0": true,
        "border-none": true,
        "focus:ring-0": true,
    },
    textarea__prefixIcon: {
        flex: true,
        "items-center": true,
        "-ml-1": true,
        "mr-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "!ml-2": true,
        "!mr-0": true,
        "text-gray-600": true,
        "dark:text-gray-300": true,
    },
    textarea__suffixIcon: {
        flex: true,
        "items-center": true,
        "-mr-1": true,
        "ml-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "!mr-2": true,
        "!ml-0": true,
        "text-gray-600": true,
        "dark:text-gray-300": true,
    },
    time__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "focus:[&::-webkit-datetime-edit-hour-field]:bg-orange-100": true,
        "focus:[&::-webkit-datetime-edit-minute-field]:bg-orange-100": true,
        "focus:[&::-webkit-datetime-edit-ampm-field]:bg-orange-100": true,
    },
    week__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "focus:[&::-webkit-datetime-edit-week-field]:bg-orange-100": true,
        "focus:[&::-webkit-datetime-edit-year-field]:bg-orange-100": true,
    },
    autocomplete__selections: {
        flex: true,
        absolute: true,
        "inset-0": true,
        "group-data-[multiple]:static": true,
        "group-data-[multiple]:block": true,
        "group-data-[empty]:hidden": true,
        "group-data-[multiple]:mt-1.5": true,
    },
    autocomplete__selectionWrapper: {
        "bg-gray-100": true,
        rounded: true,
        "group-data-[multiple]:border": true,
        "group-data-[multiple]:border-gray-300": true,
        "group-data-[multiple]:mb-1.5": true,
        "outline-none": true,
        "data-[active-selection=true]:ring-2": true,
        "data-[active-selection=true]:ring-orange-500": true,
        "dark:bg-gray-600": true,
        "dark:group-data-[multiple]:border-gray-500": true,
        "[&.formkit-dropZone]:opacity-25": true,
        "[&.formkit-touchDropZone]:opacity-25": true,
        "[&.formkit-touchDragging]:!flex": true,
        "[&.formkit-longTouch]:opacity-25": true,
    },
    autocomplete__selection: {
        rounded: true,
        just: true,
        "pl-2": true,
        "[&>*]:ml-0": true,
        "dark:text-gray-200": true,
    },
    colorpicker__outer: {
        group: true,
        "max-w-none": true,
        "min-w-0": true,
        grow: true,
        "mb-4": true,
        "data-[disabled]:select-none": true,
        "data-[disabled]:opacity-50": true,
        "text-base": true,
        "data-[disabled]:cursor-not-allowed": true,
        "data-[disabled]:pointer-events-none": true,
    },
    colorpicker__help: {
        "text-gray-500": true,
        "text-xs": true,
        "dark:text-gray-400": true,
        "group-data-[inline]:-mt-1": true,
        "group-data-[inline]:mb-2": true,
    },
    colorpicker__inner: {
        relative: true,
        "inline-flex": true,
        "!w-auto": true,
        "pl-2": true,
        "group-data-[inline]:border-none": true,
        "group-data-[inline]:shadow-none": true,
        "group-data-[inline]:p-0": true,
        "group-data-[inline]:bg-transparent": true,
        "group-data-[inline]:outline-none": true,
        "group-data-[inline]:!ring-0": true,
        "group-data-[inline]:!w-full": true,
        "group-data-[inline]:rounded": true,
    },
    colorpicker__swatchPreview: {
        "w-full": true,
        flex: true,
        "justify-start": true,
        "items-center": true,
        rounded: true,
        "text-sm": true,
        "cursor-pointer": true,
        "outline-none": true,
    },
    colorpicker__canvasSwatchPreviewWrapper: {
        relative: true,
        "before:content-['']": true,
        "before:absolute": true,
        "before:inset-0": true,
        "before:rounded": true,
        "before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true,
        "before:z-[2]": true,
    },
    colorpicker__canvasSwatchPreview: {
        "text-base": true,
        rounded: true,
        "aspect-[1/1]": true,
        "shrink-0": true,
        grow: true,
        "!w-[1.5em]": true,
    },
    colorpicker__valueString: {
        "text-base": true,
        "text-gray-700": true,
        "selection:bg-orange-100": true,
        "font-mono": true,
        "inline-block": true,
        "ml-2": true,
        "mr-1.5": true,
        "dark:text-gray-300": true,
        "dark:selection:text-gray-700": true,
    },
    colorpicker__panel: {
        absolute: true,
        "left-0": true,
        "top-full": true,
        "z-[99]": true,
        flex: true,
        "w-[100vw]": true,
        "max-w-[18.5em]": true,
        "touch-manipulation": true,
        "flex-col": true,
        rounded: true,
        border: true,
        "bg-white": true,
        "p-2": true,
        "shadow-md": true,
        "group-data-[inline]:static": true,
        "group-data-[inline]:max-w-none": true,
        "border-gray-400": true,
        "group-data-[inline]:z-auto": true,
        "group-data-[inline]:w-full": true,
        "group-data-[inline]:shadow": true,
        "group-data-[inline]:group-data-[disabled]:!cursor-not-allowed": true,
        "group-data-[inline]:group-data-[disabled]:!pointer-events-none": true,
        "group-data-[inline]:[&:has([id^=swatches]:first-child:last-child)]:w-auto": true,
        "group-data-[inline]:[&:has([id^=swatches]:first-child:last-child)_[id^=swatches]>div]:w-[1.5em]": true,
        "dark:bg-gray-800": true,
        "dark:border-gray-500": true,
        "dark:group-data-[inline]:bg-transparent": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-0": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none": true,
    },
    colorpicker__panelClose: {
        flex: true,
        "justify-end": true,
        "items-center": true,
        "text-gray-600": true,
        "mb-1.5": true,
        "-mt-1": true,
        "border-none": true,
        "bg-none": true,
        "border-b": true,
        "border-gray-300": true,
        "w-[calc(100%+1rem)]": true,
        "-ml-2": true,
        "pt-0": true,
        "pr-2": true,
        "pb-1.5": true,
        "pl-2": true,
        "dark:border-gray-600": true,
    },
    colorpicker__closeIcon: {
        "w-[2rem]": true,
        "aspect-[1/1]": true,
        "p-1": true,
        rounded: true,
        border: true,
        "[&>svg]:w-full": true,
        "[&>svg]:aspect-[1/1]": true,
        "[&>svg]:max-w-none": true,
        "[&>svg]:max-h-none": true,
    },
    colorpicker__controlGroup: {
        grid: true,
        "[grid-template-areas:'a_a_a'_'b_c_e'_'b_d_e']": true,
        "mb-2": true,
    },
    colorpicker__LS: {
        "[grid-area:a]": true,
        relative: true,
        "mb-2": true,
    },
    colorpicker__canvas: {
        block: true,
        "w-full": true,
    },
    colorpicker__canvasLS: {
        "aspect-[2/1]": true,
        "cursor-pointer": true,
        "rounded-none": true,
    },
    colorpicker__canvasHue: {
        "rounded-none": true,
    },
    colorpicker__canvasAlpha: {
        "rounded-none": true,
    },
    colorpicker__preview: {
        rounded: true,
        "after:rounded": true,
        relative: true,
        "inline-flex": true,
        "aspect-[1/1]": true,
        "overflow-hidden": true,
        "[grid-area:b]": true,
        "mr-2": true,
        "after:absolute": true,
        "after:left-0": true,
        "after:top-0": true,
        "after:h-full": true,
        "after:w-full": true,
        "after:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true,
        "after:content-['']": true,
        "w-[2em]": true,
        "dark:after:shadow-[inset_0_0_0_1px_rgba(255,255,255,0.25)]": true,
    },
    colorpicker__hue: {
        "[grid-area:c]": true,
        relative: true,
        "inline-flex": true,
        "h-3/4": true,
    },
    colorpicker__alpha: {
        "[grid-area:d]": true,
        relative: true,
        "inline-flex": true,
        "h-3/4": true,
    },
    colorpicker__eyeDropper: {
        "[grid-area:e]": true,
        "w-[2em]": true,
        "ml-2": true,
        "inline-flex": true,
        "self-center": true,
        "justify-center": true,
        "justify-self-center": true,
        "aspect-[1/1]": true,
        rounded: true,
        border: true,
        "border-gray-300": true,
        "cursor-pointer": true,
        "content-center": true,
        "items-center": true,
        "text-gray-600": true,
        "dark:border-gray-600": true,
    },
    colorpicker__eyeDropperIcon: {
        "w-auto": true,
        "[&>svg]:w-[1em]": true,
        "dark:text-gray-400": true,
    },
    colorpicker__control: {
        absolute: true,
        "bg-white": true,
        "shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)]": true,
        "-translate-y-1/2": true,
        "-translate-x-1/2": true,
        "pointer-events-none": true,
        "data-[prevent-focus-style]:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)]": true,
        "focus-visible:outline-none": true,
        "focus-visible:ring-2": true,
        "focus-visible:ring-offset-2": true,
        "focus-visible:ring-orange-500": true,
    },
    colorpicker__controlLS: {
        "w-[10px]": true,
        "h-[10px]": true,
        "rounded-full": true,
    },
    colorpicker__controlHue: {
        "w-[4px]": true,
        "h-[calc(100%-2px)]": true,
        "top-1/2": true,
        rounded: true,
    },
    colorpicker__controlAlpha: {
        "w-[4px]": true,
        "h-[calc(100%-2px)]": true,
        "top-1/2": true,
        rounded: true,
    },
    colorpicker__formatField: {
        flex: true,
        "items-center": true,
        "justify-center": true,
        grow: true,
    },
    colorpicker__colorField: {
        "bg-transparent": true,
        "text-gray-700": true,
        border: true,
        "border-gray-300": true,
        "dark:border-gray-600": true,
        "dark:text-gray-300": true,
        "dark:selection:text-gray-700": true,
    },
    colorpicker__colorInputGroup: {
        flex: true,
        "items-center": true,
        "justify-center": true,
        grow: true,
    },
    colorpicker__fieldGroup: {
        flex: true,
        "flex-col": true,
        "items-center": true,
        "justify-center": true,
        "w-full": true,
        "mr-1": true,
        "[&>input]:p-1": true,
        "[&>input]:text-sm": true,
        "[&>input]:text-gray-700": true,
        "[&>input]:selection:bg-orange-100": true,
        "[&>input]:m-0": true,
        "[&>input]:grow": true,
        "[&>input]:shrink": true,
        "[&>input]:w-full": true,
        "[&>input]:border": true,
        "[&>input]:border-gray-300": true,
        "[&>input]:rounded": true,
        "[&>input]:text-center": true,
        "[&>input]:appearance-none": true,
        "[&>input::-webkit-outer-spin-button]:appearance-none": true,
        "[&>input::-webkit-inner-spin-button]:appearance-none": true,
        "[&>input::-webkit-inner-spin-button]:m-0": true,
        "[&>input:focus]:outline-none": true,
        "[&>input:focus]:ring-1": true,
        "[&>input:focus]:ring-orange-600": true,
        "[&>input:focus]:border-orange-600": true,
        "max-[431px]:[&>input]:text-base": true,
    },
    colorpicker__fieldLabel: {
        "text-xs": true,
        "text-gray-500": true,
        "mt-1.5": true,
        "dark:text-gray-400": true,
    },
    colorpicker__formatSwitcher: {
        flex: true,
        "justify-end": true,
        "self-start": true,
        uppercase: true,
        "shrink-0": true,
        "p-1": true,
        "mt-0.5": true,
        "text-gray-600": true,
        rounded: true,
        "select-none": true,
        "dark:text-gray-400": true,
    },
    colorpicker__switchIcon: {
        "[&>svg]:w-3": true,
    },
    colorpicker__swatches: {
        "inline-flex": true,
        "flex-wrap": true,
        "w-full": true,
        "justify-self-center": true,
        "min-w-0": true,
        "mx-auto": true,
        "px-[1px]": true,
        "pt-2": true,
        "pb-2": true,
        "mt-2": true,
        "-mb-2": true,
        "border-t": true,
        "border-gray-300": true,
        "overflow-auto": true,
        "max-h-[200px]": true,
        "select-none": true,
        "first:-mt-[3px]": true,
        "first:last:-mb-[3px]": true,
        "first:last:pb-[2px]": true,
        "first:pt-px": true,
        "first:border-t-0": true,
        "dark:border-gray-600": true,
    },
    colorpicker__swatchGroup: {
        flex: true,
        "flex-wrap": true,
        "w-full": true,
        "mb-2": true,
        "last:mb-0": true,
    },
    colorpicker__swatchGroupLabel: {
        "ml-1": true,
        block: true,
        "w-full": true,
        "text-sm": true,
        "text-gray-500": true,
        "dark:text-gray-400": true,
    },
    colorpicker__swatch: {
        relative: true,
        "text-base": true,
        "w-[calc((100%/10)-0.5em)]": true,
        "max-w-[22px]": true,
        "m-[0.16em]": true,
        "cursor-pointer": true,
        "before:content-['']": true,
        "before:absolute": true,
        "before:inset-0": true,
        "before:rounded": true,
        "before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true,
        "before:pointer-events-none": true,
        "before:z-[2]": true,
        "dark:before:shadow-[inset_0_0_0_1px_rgba(255,255,255,0.25)]": true,
        "data-[active=true]:after:content-['']": true,
        "data-[active=true]:after:block": true,
        "data-[active=true]:after:absolute": true,
        "data-[active=true]:after:w-1.5": true,
        "data-[active=true]:after:h-1.5": true,
        "data-[active=true]:after:top-1/2": true,
        "data-[active=true]:after:left-1/2": true,
        "data-[active=true]:after:pointer-events-none": true,
        "data-[active=true]:after:rounded-full": true,
        "data-[active=true]:after:-translate-x-1/2": true,
        "data-[active=true]:after:-translate-y-1/2": true,
        "data-[active=true]:after:bg-white": true,
        "data-[active=true]:after:z-[2]": true,
        "data-[active=true]:after:ring-1": true,
        "data-[active=true]:after:ring-[rgba(0,0,0,0.33)]": true,
        "[&>canvas]:block": true,
        "[&>canvas]:w-full": true,
        "[&>canvas]:aspect-[1/1]": true,
        "[&>canvas]:rounded": true,
        "[&>canvas:focus-visible]:outline-none": true,
        "[&>canvas:focus-visible]:ring-2": true,
        "[&>canvas:focus-visible]:ring-orange-500": true,
        "[&>canvas:focus-visible]:ring-offset-2": true,
        "[&>canvas:focus-visible]:ring-offset-white": true,
        "dark:[&>canvas:focus-visible]:ring-offset-gray-700": true,
    },
    datepicker__inner: {
        relative: true,
    },
    datepicker__removeSelection: {
        "self-stretch": true,
        "text-base": true,
        flex: true,
        "items-center": true,
        "ml-1": true,
        "mr-2": true,
        "text-gray-700": true,
        "hover:text-red-400": true,
        "z-10": true,
        "dark:text-gray-300": true,
    },
    datepicker__clearIcon: {
        "[&>svg]:w-[0.75em]": true,
    },
    datepicker__panelWrapper: {
        "group/panel": true,
        absolute: true,
        "min-w-[20em]": true,
        "top-[calc(100%_+_0.5em)]": true,
        "left-0": true,
        "shadow-[0_0_1.25em_rgba(0,0,0,.25)]": true,
        rounded: true,
        "p-4": true,
        "bg-white": true,
        "z-10": true,
        "dark:bg-gray-800": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-0": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:w-full": true,
    },
    datepicker__panelHeader: {
        grid: true,
        "grid-cols-[2.5em_1fr_2.5em]": true,
        "justify-center": true,
        "items-center": true,
        "border-b-2": true,
        "border-gray-300": true,
        "mb-2": true,
        "pb-2.5": true,
        "dark:border-gray-600": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:grid-cols-[2.5em_1fr_2.5em_2.5em]": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:group-data-[panel=time]/panel:grid-cols-[2.5em_1fr_2.5em]": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:group-data-[panel=month]/panel:grid-cols-[2.5em_1fr_2.5em]": true,
    },
    datepicker__panelClose: {
        "aspect-[1/1]": true,
        border: true,
        "border-gray-300": true,
        rounded: true,
        flex: true,
        "items-center": true,
        "justify-center": true,
        "text-gray-700": true,
        "[&_svg]:w-[1.25em]": true,
        "dark:text-gray-300": true,
        "dark:border-gray-600": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-data-[panel=time]/panel:col-start-3": true,
        "[@media(max-width:431px)_and_(hover:none)]:group-data-[panel=month]/panel:col-start-3": true,
    },
    datepicker__panel: {
        flex: true,
        "justify-center": true,
    },
    datepicker__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "placeholder:text-gray-400": true,
    },
    datepicker__monthsHeader: {
        flex: true,
        "items-center": true,
        "justify-center": true,
        "col-start-2": true,
        "col-end-2": true,
    },
    datepicker__timeHeader: {
        flex: true,
        "items-center": true,
        "justify-center": true,
        "col-start-2": true,
        "col-end-2": true,
    },
    datepicker__months: {
        grid: true,
        "grid-cols-3": true,
        "w-full": true,
    },
    datepicker__month: {
        "m-1.5": true,
        "p-1.5": true,
        "text-center": true,
        "text-gray-700": true,
        rounded: true,
        "bg-gray-200": true,
        "aria-selected:!bg-orange-600": true,
        "aria-selected:!text-white": true,
        "focus:outline": true,
        "focus:outline-2": true,
        "focus:outline-orange-600": true,
        "focus:outline-offset-2": true,
        "focus:bg-white": true,
        "focus:text-gray-700": true,
        "data-[is-extra=true]:opacity-25": true,
        "group-data-[disabled=true]:opacity-50": true,
        "group-data-[disabled=true]:cursor-default": true,
        "group-data-[disabled=true]:pointer-events-none": true,
        "dark:bg-gray-700": true,
        "dark:text-gray-300": true,
    },
    datepicker__yearsHeader: {
        flex: true,
        "items-center": true,
        "justify-center": true,
        "text-gray-700": true,
        "col-start-2": true,
        "col-end-2": true,
        "dark:text-gray-300": true,
    },
    datepicker__years: {
        grid: true,
        "grid-cols-5": true,
        "w-full": true,
    },
    datepicker__year: {
        "text-base": true,
        "text-center": true,
        "text-gray-700": true,
        "items-center": true,
        "m-1.5": true,
        "p-1.5": true,
        rounded: true,
        "bg-gray-200": true,
        "aria-selected:!bg-orange-600": true,
        "aria-selected:!text-white": true,
        "focus:outline": true,
        "focus:outline-2": true,
        "focus:outline-orange-600": true,
        "focus:outline-offset-2": true,
        "focus:bg-white": true,
        "data-[is-extra=true]:opacity-25": true,
        "group-data-[disabled=true]:opacity-50": true,
        "group-data-[disabled=true]:cursor-default": true,
        "group-data-[disabled=true]:pointer-events-none": true,
        "dark:bg-gray-700": true,
        "dark:text-gray-300": true,
    },
    datepicker__weekDays: {
        grid: true,
        "grid-cols-7": true,
    },
    datepicker__weekDay: {
        "w-[2.25em]": true,
        "text-gray-700": true,
        "m-1.5": true,
        rounded: true,
        "font-medium": true,
        lowercase: true,
        "dark:text-gray-500": true,
    },
    datepicker__calendarWeeks: {
        "": true,
    },
    datepicker__week: {
        grid: true,
        "grid-cols-7": true,
        "group-data-[disabled=true]:opacity-50": true,
        "group-data-[disabled=true]:cursor-default": true,
        "group-data-[disabled=true]:pointer-events-none": true,
    },
    datepicker__dayCell: {
        flex: true,
        "items-center": true,
        "justify-center": true,
        "text-center": true,
        "text-gray-700": true,
        "w-[2.25em]": true,
        "h-[2.25em]": true,
        "m-1": true,
        "p-2": true,
        rounded: true,
        "bg-gray-200": true,
        "aria-selected:bg-orange-600": true,
        "aria-selected:text-white": true,
        "focus:outline": true,
        "focus:outline-2": true,
        "focus:outline-orange-600": true,
        "focus:outline-offset-2": true,
        "focus:bg-white": true,
        "data-[is-extra=true]:opacity-25": true,
        "data-[disabled=true]:opacity-50": true,
        "data-[disabled=true]:cursor-default": true,
        "data-[disabled=true]:pointer-events-none": true,
        "dark:bg-gray-600": true,
        "dark:text-gray-300": true,
        "dark:aria-selected:bg-orange-600": true,
        "dark:aria-selected:text-white": true,
        "dark:focus:outline-orange-600": true,
        "dark:focus:bg-gray-200": true,
        "dark:focus:text-gray-700": true,
    },
    datepicker__timeInput: {
        "w-full": true,
        "border-2": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "text-gray-700": true,
        "border-gray-300": true,
        rounded: true,
        "p-1.5": true,
        "my-2.5": true,
        "focus-visible:outline-orange-600": true,
        "dark:text-gray-300": true,
        "dark:bg-transparent": true,
        "dark:border-gray-600": true,
    },
    datepicker__daysHeader: {
        flex: true,
        "items-center": true,
        "justify-center": true,
    },
    datepicker__prev: {
        "mr-auto": true,
        "px-2.5": true,
        "py-0.5": true,
        "hover:bg-gray-100": true,
        rounded: true,
        "col-start-1": true,
        "col-end-1": true,
        "focus-visible:outline-none": true,
        "focus-visible:ring-2": true,
        "focus-visible:ring-orange-500": true,
        "focus-visible:ring-offset-2": true,
    },
    datepicker__prevLabel: {
        hidden: true,
    },
    datepicker__prevIcon: {
        flex: true,
        "w-[0.75em]": true,
        "select-none": true,
        "text-gray-700": true,
        "[&>svg]:w-full": true,
        "dark:text-gray-300": true,
    },
    datepicker__dayButton: {
        "appearance-none": true,
        "text-gray-700": true,
        "cursor-pointer": true,
        "px-2.5": true,
        "py-0.5": true,
        "border-2": true,
        "border-gray-300": true,
        rounded: true,
        "mx-1": true,
        "hover:border-orange-600": true,
        "focus-visible:outline-none": true,
        "focus-visible:ring-2": true,
        "focus-visible:ring-orange-500": true,
        "focus-visible:ring-offset-2": true,
        "dark:text-gray-300": true,
        "dark:border-gray-600": true,
        "dark:hover:border-orange-500": true,
    },
    datepicker__monthButton: {
        "appearance-none": true,
        "text-gray-700": true,
        "cursor-pointer": true,
        "px-2.5": true,
        "py-0.5": true,
        "border-2": true,
        "border-gray-300": true,
        rounded: true,
        "mx-1": true,
        "hover:border-orange-600": true,
        "focus-visible:outline-none": true,
        "focus-visible:ring-2": true,
        "focus-visible:ring-orange-500": true,
        "focus-visible:ring-offset-2": true,
        "dark:text-gray-300": true,
        "dark:border-gray-600": true,
        "dark:hover:border-orange-500": true,
    },
    datepicker__yearButton: {
        "appearance-none": true,
        "text-gray-700": true,
        "cursor-pointer": true,
        "px-2.5": true,
        "py-0.5": true,
        "border-2": true,
        "border-gray-300": true,
        rounded: true,
        "mx-1": true,
        "hover:border-orange-600": true,
        "focus-visible:outline-none": true,
        "focus-visible:ring-2": true,
        "focus-visible:ring-orange-500": true,
        "focus-visible:ring-offset-2": true,
        "dark:text-gray-300": true,
        "dark:border-gray-600": true,
        "dark:hover:border-orange-500": true,
    },
    datepicker__next: {
        "ml-auto": true,
        "px-2.5": true,
        "py-0.5": true,
        rounded: true,
        "hover:bg-gray-100": true,
        "hover:rounded": true,
        "col-start-3": true,
        "col-end-3": true,
        "focus-visible:outline-none": true,
        "focus-visible:ring-2": true,
        "focus-visible:ring-orange-500": true,
        "focus-visible:ring-offset-2": true,
    },
    datepicker__nextLabel: {
        hidden: true,
    },
    datepicker__nextIcon: {
        flex: true,
        "w-[0.75em]": true,
        "select-none": true,
        "text-gray-700": true,
        "[&>svg]:w-full": true,
        "dark:text-gray-300": true,
    },
    datepicker__openButton: {
        "appearance-none": true,
        "border-0": true,
        "bg-transparent": true,
        flex: true,
        "p-0": true,
        "self-stretch": true,
        "cursor-pointer": true,
        "focus-visible:outline-none": true,
        "focus-visible:ring-2": true,
        "focus-visible:ring-orange-500": true,
        "focus-visible:ring-offset-2": true,
        "focus-visible:rounded": true,
    },
    datepicker__calendarIcon: {
        "text-gray-600": true,
        "focus-visible:text-orange-600": true,
        flex: true,
        "w-[1em]": true,
        "grow-0": true,
        "shrink-0": true,
        "self-stretch": true,
        "select-none": true,
        "[&>svg]:w-full": true,
        "[&>svg]:m-auto": true,
        "[&>svg]:max-h-[1em]": true,
        "[&>svg]:max-w-[1em]": true,
    },
    dropdown__placeholder: {
        "text-gray-400": true,
        grow: true,
        "dark:text-gray-400/50": true,
    },
    dropdown__selector: {
        flex: true,
        grow: true,
        "justify-between": true,
        "w-full": true,
        "py-2": true,
        "pl-3": true,
        "pr-0": true,
        "text-base": true,
        "text-gray-700": true,
        "text-left": true,
        "group-data-[disabled]:!cursor-not-allowed": true,
        "group-data-[prefix-icon]:!pl-0": true,
        "group-data-[suffix-icon]:!pr-0": true,
        "data-[placeholder]:text-gray-400": true,
        "selection:bg-orange-100": true,
        "dark:data-[placeholder]:text-gray-400/50": true,
        "dark:text-gray-300": true,
    },
    dropdown__selectIcon: {
        "shrink-0": true,
    },
    dropdown__selectionsWrapper: {
        "w-[85%]": true,
        "overflow-hidden": true,
    },
    dropdown__selection: {
        "[&>*]:ml-0": true,
    },
    dropdown__selections: {
        "inline-flex": true,
        "items-center": true,
    },
    dropdown__selectionsItem: {
        "whitespace-nowrap": true,
        "mr-1": true,
    },
    dropdown__tagWrapper: {
        "[&.formkit-dropZone_.formkit-tag]:opacity-25": true,
        "[&.formkit-touchDropZone_.formkit-tag]:opacity-25": true,
    },
    dropdown__truncationCount: {
        flex: true,
        "items-center": true,
        "justify-center": true,
        "h-[1.5em]": true,
        rounded: true,
        "bg-gray-400": true,
        "text-white": true,
        "whitespace-nowrap": true,
        "text-[11px]": true,
        "[line-height:1em]": true,
        "tracking-tighter": true,
        "leading-0": true,
        "py-1": true,
        "px-1": true,
        "shrink-0": true,
        "my-auto": true,
    },
    mask__inner: {
        relative: true,
    },
    mask__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "group-data-[has-overlay]:!caret-gray-700": true,
        "dark:group-data-[has-overlay]:!caret-gray-300": true,
    },
    rating__inner: {
        "text-gray-300": true,
    },
    rating__itemsWrapper: {
        relative: true,
        "inline-flex": true,
        "focus:border-orange-600": true,
    },
    rating__onItemRow: {
        "h-full": true,
        "w-full": true,
    },
    rating__offItemRow: {
        "h-full": true,
        "w-full": true,
    },
    rating__onItemWrapper: {
        "[&>*]:w-full": true,
        "[&>*]:h-full": true,
        "w-full": true,
        "h-full": true,
        "text-yellow-400": true,
    },
    rating__offItemWrapper: {
        "text-gray-400": true,
        "w-full": true,
        "h-full": true,
        "[&>*]:w-full": true,
        "[&>*]:h-full": true,
        "dark:text-gray-500": true,
    },
    rating__ratingItem: {
        relative: true,
        "focus-within:outline": true,
        "focus-within:outline-orange-600": true,
        "w-[1.5em]": true,
        "h-[1.5em]": true,
    },
    rating__itemLabelInner: {
        "h-px": true,
        "w-px": true,
        "overflow-hidden": true,
        absolute: true,
        "white-space-nowrap": true,
    },
    rating__itemLabel: {
        absolute: true,
        "h-full": true,
    },
    rating__ratingIcon: {
        "w-[1.5em]": true,
        "h-[1.5em]": true,
        flex: true,
    },
    rating__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "outline-none": true,
    },
    rating__messages: {
        "mt-1.5": true,
    },
    repeater__outer: {
        "min-w-0": true,
        grow: true,
        "mb-4": true,
        "text-base": true,
        "group/repeater": true,
        "max-w-full": true,
    },
    repeater__fieldset: {
        "min-w-0": true,
    },
    repeater__legend: {
        block: true,
        "text-gray-700": true,
        "text-sm": true,
        "font-bold": true,
        "dark:text-gray-300": true,
        "mb-2": true,
    },
    repeater__content: {
        "min-w-0": true,
        grow: true,
        "p-5": true,
        flex: true,
        "flex-col": true,
        "align-center": true,
        "[&>div[data-type]]:max-w-none": true,
        "[&>div[data-type]:last-child]:mb-0": true,
    },
    repeater__addButton: {
        "!mb-0": true,
        "group-data-[disabled]/repeater:pointer-events-none": true,
        "group-data-[disabled]/repeater:opacity-50": true,
        "group-data-[disabled]/repeater:grayscale": true,
    },
    repeater__controlLabel: {
        absolute: true,
        "opacity-0": true,
        "pointer-events-none": true,
        "text-[0px]": true,
    },
    repeater__controls: {
        flex: true,
        "flex-col": true,
        "items-center": true,
        "justify-center": true,
        "bg-gray-50": true,
        "p-2": true,
        "[&>li]:aspect-[1/1]": true,
        "dark:bg-gray-800": true,
        rounded: true,
        "rounded-tl-none": true,
        "rounded-bl-none": true,
    },
    repeater__downControl: {
        "w-[1.5em]": true,
        "h-[1.5em]": true,
        "my-1.5": true,
        "mx-auto": true,
        flex: true,
        "items-center": true,
        "appearance-none": true,
        "justify-center": true,
        "aspect-[1/1]": true,
        "text-gray-500": true,
        "hover:text-orange-600": true,
        "disabled:hover:text-inherit": true,
        "disabled:opacity-25": true,
        "disabled:!text-gray-500": true,
        "dark:text-gray-300": true,
        "dark:disabled:!text-gray-300": true,
        "dark:hover:text-orange-500": true,
    },
    repeater__upControl: {
        "w-[1.5em]": true,
        "h-[1.5em]": true,
        "my-1.5": true,
        "mx-auto": true,
        flex: true,
        "items-center": true,
        "appearance-none": true,
        "justify-center": true,
        "aspect-[1/1]": true,
        "text-gray-500": true,
        "hover:text-orange-600": true,
        "disabled:hover:text-inherit": true,
        "disabled:opacity-25": true,
        "disabled:!text-gray-500": true,
        "dark:text-gray-300": true,
        "dark:disabled:!text-gray-300": true,
        "dark:hover:text-orange-500": true,
    },
    repeater__removeControl: {
        "w-[1.5em]": true,
        "h-[1.5em]": true,
        "my-1.5": true,
        "mx-auto": true,
        flex: true,
        "items-center": true,
        "appearance-none": true,
        "justify-center": true,
        "aspect-[1/1]": true,
        "text-gray-500": true,
        "hover:text-orange-600": true,
        "disabled:hover:text-inherit": true,
        "disabled:opacity-25": true,
        "disabled:!text-gray-500": true,
        "dark:text-gray-300": true,
        "dark:disabled:!text-gray-300": true,
        "dark:hover:text-orange-500": true,
    },
    repeater__insertControl: {
        "w-[1.5em]": true,
        "h-[1.5em]": true,
        "my-1.5": true,
        "mx-auto": true,
        flex: true,
        "items-center": true,
        "appearance-none": true,
        "justify-center": true,
        "aspect-[1/1]": true,
        "text-gray-500": true,
        "hover:text-orange-600": true,
        "disabled:hover:text-inherit": true,
        "disabled:opacity-25": true,
        "disabled:!text-gray-500": true,
        "dark:text-gray-300": true,
        "dark:disabled:!text-gray-300": true,
        "dark:hover:text-orange-500": true,
    },
    repeater__help: {
        "text-gray-500": true,
        "text-xs": true,
        "dark:text-gray-400": true,
        "mb-2": true,
        "-mt-1": true,
    },
    repeater__item: {
        flex: true,
        relative: true,
        "w-full": true,
        "mb-2": true,
        "bg-white": true,
        border: true,
        "border-gray-300": true,
        rounded: true,
        shadow: true,
        "dark:border-gray-600": true,
        "dark:bg-transparent": true,
        "[&.formkit-dropZone]:opacity-30": true,
        "[&.formkit-dropZone]:blur-[2px]": true,
    },
    repeater__dragHandleWrapper: {
        relative: true,
        "w-8": true,
        "bg-gray-50": true,
        rounded: true,
        "rounded-tr-none": true,
        "rounded-br-none": true,
        "dark:bg-gray-800": true,
    },
    repeater__dragHandle: {
        "w-full": true,
        "h-full": true,
        flex: true,
        absolute: true,
        "top-0": true,
        "left-0": true,
        "cursor-grab": true,
        "active:cursor-grabbing": true,
    },
    repeater__dragHandleIcon: {
        "w-2": true,
        "m-auto": true,
        "text-gray-500": true,
        "dark:text-gray-400": true,
        "[&>svg>path]:fill-current": true,
    },
    repeater__moveDownIcon: {
        block: true,
        "w-[0.75em]": true,
        "aspect-[1/1]": true,
    },
    repeater__moveUpIcon: {
        block: true,
        "w-[0.75em]": true,
        "aspect-[1/1]": true,
    },
    repeater__removeIcon: {
        block: true,
        "w-[1.25em]": true,
        "aspect-[1/1]": true,
    },
    repeater__addIcon: {
        block: true,
        "w-[1.25em]": true,
        "aspect-[1/1]": true,
    },
    slider__outer: {
        group: true,
        "max-w-none": true,
        "min-w-0": true,
        grow: true,
        "mb-4": true,
        "data-[disabled]:select-none": true,
        "data-[disabled]:opacity-50": true,
        "text-base": true,
        "data-[disabled]:pointer-events-none": true,
    },
    slider__help: {
        "text-gray-500": true,
        "text-xs": true,
        "dark:text-gray-400": true,
        "-mt-0.5": true,
        "mb-1.5": true,
    },
    slider__sliderInner: {
        flex: true,
        "items-center": true,
        "[&>[data-type=number]]:mb-0": true,
        "[&>[data-type=number]]:ml-2.5": true,
        "[&>[data-type=number]]:shrink": true,
        "[&>[data-type=number]]:grow-0": true,
        "[&[data-has-mark-labels=true]_[id^=track]]:mb-5": true,
    },
    slider__track: {
        grow: true,
        relative: true,
        "z-20": true,
        "py-2.5": true,
        "select-none": true,
    },
    slider__trackWrapper: {
        "px-[2px]": true,
        "rounded-full": true,
        "bg-gray-300": true,
        "dark:bg-gray-500": true,
    },
    slider__trackInner: {
        "h-1.5": true,
        "mx-0.5": true,
        relative: true,
    },
    slider__prefixIcon: {
        flex: true,
        "items-center": true,
        "-ml-1": true,
        "mr-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "text-gray-600": true,
        "dark:text-gray-300": true,
    },
    slider__suffixIcon: {
        flex: true,
        "items-center": true,
        "-mr-1": true,
        "ml-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
        "text-gray-600": true,
        "dark:text-gray-300": true,
    },
    slider__fill: {
        "h-full": true,
        "rounded-full": true,
        absolute: true,
        "top-0": true,
        "-mx-1": true,
        "bg-orange-600": true,
        "group-data-[disabled]:bg-gray-500": true,
    },
    slider__marks: {
        absolute: true,
        "pointer-events-none": true,
        "inset-0": true,
    },
    slider__mark: {
        absolute: true,
        "top-1/2": true,
        "w-[3px]": true,
        "h-[3px]": true,
        "rounded-full": true,
        "-translate-x-1/2": true,
        "-translate-y-1/2": true,
        "bg-gray-400": true,
        "data-[active=true]:bg-white": true,
    },
    slider__markLabel: {
        absolute: true,
        "top-[calc(100%+0.5em)]": true,
        "left-1/2": true,
        "text-gray-400": true,
        "text-xs": true,
        "-translate-x-1/2": true,
    },
    slider__handles: {
        "m-0": true,
        "p-0": true,
        "list-none": true,
    },
    slider__handle: {
        group: true,
        "select-none": true,
        "w-4": true,
        "h-4": true,
        "rounded-full": true,
        "bg-white": true,
        absolute: true,
        "top-1/2": true,
        "left-0": true,
        "z-30": true,
        "-translate-x-1/2": true,
        "-translate-y-1/2": true,
        "shadow-[inset_0_0_0_1px_rgba(0,0,0,0.1),0_1px_2px_0_rgba(0,0,0,0.5)]": true,
        "focus-visible:outline-0": true,
        "focus-visible:ring-2": true,
        "ring-orange-600": true,
        "data-[is-target=true]:z-20": true,
        "dark:bg-gray-200": true,
    },
    slider__tooltip: {
        absolute: true,
        "bottom-full": true,
        "left-1/2": true,
        "-translate-x-1/2": true,
        "-translate-y-[4px]": true,
        "bg-orange-600": true,
        "text-white": true,
        "py-1": true,
        "px-1.5": true,
        "text-xs": true,
        "leading-none": true,
        "whitespace-nowrap": true,
        rounded: true,
        "opacity-0": true,
        "pointer-events-none": true,
        "transition-opacity": true,
        'after:content-[""]': true,
        "after:absolute": true,
        "after:top-full": true,
        "after:left-1/2": true,
        "after:-translate-x-1/2": true,
        "after:-translate-y-[1px]": true,
        "after:border-4": true,
        "after:border-transparent": true,
        "after:border-t-orange-600": true,
        "group-hover:opacity-100": true,
        "group-focus-visible:opacity-100": true,
        "group-data-[show-tooltip=true]:opacity-100": true,
    },
    slider__linkedValues: {
        flex: true,
        "items-start": true,
        "justify-between": true,
    },
    slider__minValue: {
        grow: true,
        "!max-w-[45%]": true,
        "mb-0": true,
        "[&>div>div]:relative": true,
        '[&>div>div::after]:content-[""]': true,
        "[&>div>div::after]:absolute": true,
        "[&>div>div::after]:top-1/2": true,
        "[&>div>div::after]:left-[105.5%]": true,
        "[&>div>div::after]:w-[12%]": true,
        "[&>div>div::after]:h-[1px]": true,
        "[&>div>div::after]:bg-gray-400": true,
        "dark:[&>div>div::after]:bg-gray-500": true,
    },
    slider__maxValue: {
        grow: true,
        "!max-w-[45%]": true,
        "mb-0": true,
        relative: true,
    },
    slider__chart: {
        relative: true,
        "z-20": true,
        "mb-2": true,
        flex: true,
        "justify-between": true,
        "items-center": true,
        "w-full": true,
        "aspect-[3/1]": true,
    },
    slider__chartBar: {
        absolute: true,
        "bottom-0": true,
        "h-full": true,
        "bg-gray-400": true,
        "data-[active=false]:bg-gray-300": true,
        "dark:bg-gray-500": true,
        "dark:data-[active=false]:bg-gray-600": true,
    },
    taglist__inner: {
        "py-2": true,
        "pr-0": true,
        "pl-0": true,
    },
    taglist__tags: {
        "pl-3": true,
    },
    taglist__tagWrapper: {
        "[&.formkit-dropZone_.formkit-tag]:opacity-25": true,
        "[&.formkit-touchDropZone_.formkit-tag]:opacity-25": true,
    },
    taglist__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "!p-0": true,
        "!w-[0%]": true,
        "min-w-[1em]": true,
        "inline-block": true,
        "-mt-1": true,
        "first:mt-0": true,
        "first:mb-1": true,
    },
    taglist__listboxButton: {
        "ml-auto": true,
        "shrink-0": true,
    },
    toggle__outer: {
        group: true,
        "min-w-0": true,
        grow: true,
        "mb-4": true,
        "data-[disabled]:select-none": true,
        "data-[disabled]:opacity-50": true,
        "text-base": true,
        "max-w-none": true,
    },
    toggle__altLabel: {
        block: true,
        "w-full": true,
        "mb-1.5": true,
        "font-bold": true,
        "text-xs": true,
        "text-gray-700": true,
        "dark:text-gray-300": true,
    },
    toggle__inner: {
        peer: true,
        "inline-block": true,
        "mr-2": true,
    },
    toggle__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        peer: true,
        absolute: true,
        "opacity-0": true,
        "w-0": true,
        "h-0": true,
    },
    toggle__label: {
        block: true,
        "text-gray-700": true,
        "text-sm": true,
        "font-bold": true,
        "mb-1": true,
        "dark:text-gray-300": true,
        "peer-first:font-normal": true,
        "peer-first:mb-0": true,
    },
    toggle__innerLabel: {
        absolute: true,
        "text-gray-200": true,
        "text-[10px]": true,
        "font-bold": true,
        "select-none": true,
        "left-full": true,
        "top-1/2": true,
        "-translate-x-full": true,
        "-translate-y-1/2": true,
        "px-1": true,
    },
    toggle__thumb: {
        relative: true,
        "p-0.5": true,
        "left-0": true,
        "aspect-[1/1]": true,
        "rounded-full": true,
        "transition-all": true,
        "w-[1.25em]": true,
        "bg-gray-50": true,
        "text-gray-600": true,
        "shadow-base": true,
    },
    toggle__track: {
        "p-0.5": true,
        "min-w-[3em]": true,
        relative: true,
        "cursor-pointer": true,
        "select-none": true,
        "rounded-full": true,
        "transition-all": true,
        "bg-gray-400": true,
        "peer-checked:bg-orange-600": true,
        "peer-checked:[&>div:last-child]:left-full": true,
        "peer-checked:[&>div:last-child]:-translate-x-full": true,
        "peer-checked:[&>div:first-child:not(:last-child)]:left-0": true,
        "peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0": true,
        "shadow-sm": true,
        "peer-focus-visible:ring-2": true,
        "peer-focus-visible:ring-orange-500": true,
        "peer-focus-visible:ring-offset-2": true,
        "dark:bg-gray-500": true,
    },
    toggle__valueLabel: {
        "font-bold": true,
        "text-xs": true,
        "text-gray-700": true,
        "dark:text-gray-300": true,
    },
    toggle__wrapper: {
        flex: true,
        "flex-wrap": true,
        "items-center": true,
        "mb-1.5": true,
    },
    togglebuttons__wrapper: {
        "mb-1.5": true,
    },
    togglebuttons__options: {
        "group/options": true,
        "inline-flex": true,
        "data-[vertical=true]:flex-col": true,
    },
    togglebuttons__option: {
        "group/option": true,
    },
    togglebuttons__input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
        "!px-4": true,
        "!mb-0": true,
        relative: true,
        "focus:z-10": true,
        "group-data-[vertical=true]/options:w-full": true,
        "justify-center": true,
        "bg-orange-50": true,
        "disabled:opacity-50": true,
        "disabled:cursor-not-allowed": true,
        "group-data-[disabled]:disabled:opacity-100": true,
        "dark:bg-transparent": true,
        "dark:disabled:bg-transparent": true,
        "dark:disabled:text-orange-500": true,
        "dark:text-orange-500": true,
        "aria-[pressed=true]:bg-orange-600": true,
        "aria-[pressed=true]:text-white": true,
        "dark:aria-[pressed=true]:bg-orange-600": true,
        "dark:aria-[pressed=true]:text-white": true,
        "group-[]/option:!rounded-none": true,
        "group-data-[vertical=false]/options:group-first/option:!rounded": true,
        "group-data-[vertical=true]/options:group-first/option:!rounded": true,
        "group-data-[vertical=false]/options:group-first/option:!rounded-tr-none": true,
        "group-data-[vertical=false]/options:group-first/option:!rounded-br-none": true,
        "group-data-[vertical=true]/options:group-first/option:!rounded-bl-none": true,
        "group-data-[vertical=true]/options:group-first/option:!rounded-br-none": true,
        "group-data-[vertical=false]/options:group-last/option:!rounded": true,
        "group-data-[vertical=true]/options:group-last/option:!rounded": true,
        "group-data-[vertical=false]/options:group-last/option:!rounded-tl-none": true,
        "group-data-[vertical=false]/options:group-last/option:!rounded-bl-none": true,
        "group-data-[vertical=true]/options:group-last/option:!rounded-tl-none": true,
        "group-data-[vertical=true]/options:group-last/option:!rounded-tr-none": true,
        "group-data-[vertical=false]/options:group-[]/option:!border-r-0": true,
        "group-data-[vertical=false]/options:group-last/option:!border-r": true,
        "group-data-[vertical=false]/options:group-[]/option:aria-[pressed=true]:border-x-orange-500": true,
        "group-data-[vertical=false]/options:group-first/option:aria-[pressed=true]:border-l-orange-600": true,
        "group-data-[vertical=false]/options:group-last/option:aria-[pressed=true]:border-r-orange-600": true,
        "dark:group-data-[vertical=false]/options:group-[]/option:aria-[pressed=true]:border-x-orange-600": true,
        "dark:group-data-[vertical=false]/options:group-first/option:aria-[pressed=true]:border-l-orange-600": true,
        "dark:group-data-[vertical=false]/options:group-last/option:aria-[pressed=true]:border-r-orange-600": true,
        "group-data-[vertical=true]/options:group-[]/option:!border-b-0": true,
        "group-data-[vertical=true]/options:group-last/option:!border-b": true,
        "group-data-[vertical=true]/options:group-[]/option:aria-[pressed=true]:border-y-orange-500": true,
        "group-data-[vertical=true]/options:group-first/option:aria-[pressed=true]:border-t-orange-600": true,
        "group-data-[vertical=true]/options:group-last/option:aria-[pressed=true]:border-b-orange-600": true,
        "dark:group-data-[vertical=true]/options:group-[]/option:aria-[pressed=true]:border-y-orange-600": true,
        "dark:group-data-[vertical=true]/options:group-first/option:aria-[pressed=true]:border-t-orange-600": true,
        "dark:group-data-[vertical=true]/options:group-last/option:aria-[pressed=true]:border-b-orange-600": true,
    },
    transferlist__outer: {
        group: true,
        "min-w-0": true,
        grow: true,
        "mb-4": true,
        "data-[disabled]:select-none": true,
        "data-[disabled]:opacity-50": true,
        "text-base": true,
        "max-w-none": true,
        "[&_.dnd-placeholder]:bg-orange-600": true,
        "[&_.dnd-placeholder]:text-white": true,
    },
    transferlist__wrapper: {
        flex: true,
        "flex-col": true,
        "sm:flex-row": true,
        "justify-between": true,
        "w-full": true,
        "max-w-none": true,
    },
    transferlist__help: {
        "text-gray-500": true,
        "text-xs": true,
        "dark:text-gray-400": true,
        "pb-2": true,
    },
    transferlist__transferlist: {
        grow: true,
        shrink: true,
        "min-w-0": true,
        shadow: true,
        "group-[]/repeater:shadow-none": true,
        "group-[]/multistep:shadow-none": true,
        "aspect-[4/5]": true,
        flex: true,
        "flex-col": true,
        "h-[350px]": true,
        border: true,
        "border-gray-300": true,
        rounded: true,
        "overflow-hidden": true,
        "select-none": true,
        "[&:has(:focus-visible)]:ring-1": true,
        "[&:has(:focus-visible)]:ring-orange-500": true,
        "dark:border-gray-600": true,
        "dark:bg-gray-900/50": true,
    },
    transferlist__transferlistHeader: {
        flex: true,
        "bg-gray-100": true,
        "text-gray-600": true,
        "text-sm": true,
        "justify-between": true,
        "items-center": true,
        "border-b": true,
        "border-gray-300": true,
        "py-2": true,
        "px-2.5": true,
        "dark:bg-gray-700": true,
        "dark:border-gray-600": true,
        "dark:text-gray-400": true,
    },
    transferlist__transferlistHeaderItemCount: {
        "ml-auto": true,
        "text-xs": true,
        "min-w-[1.5em]": true,
        "[line-height:1.5em]": true,
        "px-2": true,
        "text-center": true,
        "rounded-xl": true,
        "bg-gray-200": true,
        "text-gray-700": true,
        "dark:bg-gray-500": true,
        "dark:text-gray-300": true,
    },
    transferlist__transferlistListItems: {
        "list-none": true,
        "bg-white": true,
        "h-full": true,
        "overflow-x-hidden": true,
        "overflow-y-auto": true,
        "dark:bg-transparent": true,
        "outline-none": true,
    },
    transferlist__transferlistListItem: {
        "py-2": true,
        "px-2": true,
        "text-gray-700": true,
        "ring-1": true,
        "ring-gray-200": true,
        "aria-selected:bg-orange-100": true,
        "data-[is-active=true]:bg-orange-100": true,
        "data-[is-active=true]:ring-orange-200": true,
        "aria-selected:ring-orange-200": true,
        relative: true,
        flex: true,
        "cursor-pointer": true,
        "items-center": true,
        "bg-white": true,
        "pl-[1.5em]": true,
        "first:-mt-px": true,
        "first:border-t": true,
        "aria-selected:z-[2]": true,
        "aria-selected:border-transparent": true,
        "aria-selected:ring-1": true,
        "data-[is-active=true]:z-[2]": true,
        "data-[is-active=true]:border-transparent": true,
        "data-[is-active=true]:ring-1": true,
        "group-data-[is-max=true]:cursor-not-allowed": true,
        "dark:bg-gray-800": true,
        "dark:text-gray-300": true,
        "dark:data-[is-active=true]:bg-orange-900": true,
        "dark:aria-selected:bg-orange-900": true,
        "dark:ring-gray-700": true,
        "dark:data-[is-active=true]:ring-orange-600": true,
        "dark:aria-selected:ring-orange-600": true,
        "[&.formkit-dropZone]:bg-orange-100": true,
        "[&.formkit-selectionDropZone]:bg-orange-100": true,
        "[&.formkit-touchDropZone]:bg-orange-100": true,
        "[&.formkit-touchSelectionDropZone]:bg-orange-100": true,
        "[&.formkit-longTouch]:bg-orange-100": true,
        "dark:[&.formkit-dropZone]:bg-orange-900": true,
        "dark:[&.formkit-selectionDropZone]:bg-orange-900": true,
        "dark:[&.formkit-touchDropZone]:bg-orange-900": true,
        "dark:[&.formkit-touchSelectionDropZone]:bg-orange-900": true,
        "dark:[&.formkit-longTouch]:bg-orange-900": true,
    },
    transferlist__transferlistOption: {
        "text-sm": true,
    },
    transferlist__transferControls: {
        "inline-flex": true,
        "grow-0": true,
        shrink: true,
        border: true,
        "border-gray-300": true,
        "flex-row": true,
        "sm:flex-col": true,
        "justify-center": true,
        "my-2": true,
        "sm:my-auto": true,
        "mx-auto": true,
        "sm:mx-2": true,
        rounded: true,
        "overflow-clip": true,
        "shadow-none": true,
        "group-[]/repeater:shadow-none": true,
        "group-[]/multistep:shadow-none": true,
        "dark:border-gray-800": true,
    },
    transferlist__sourceEmptyMessage: {
        "appearance-none": true,
        "border-none": true,
        "w-full": true,
        "my-2": true,
        "text-center": true,
        "text-gray-500": true,
        italic: true,
    },
    transferlist__sourceListItems: {
        "group-data-[is-max=true]:opacity-50": true,
    },
    transferlist__targetEmptyMessage: {
        "appearance-none": true,
        "border-none": true,
        "w-full": true,
        "my-2": true,
        "text-center": true,
        "text-gray-500": true,
        italic: true,
    },
    transferlist__emptyMessageInner: {
        flex: true,
        "items-center": true,
        "justify-center": true,
        "p-2": true,
        "text-sm": true,
    },
    transferlist__transferlistControls: {
        "bg-white": true,
        "p-2": true,
        "border-b": true,
        "border-gray-200": true,
        "dark:bg-gray-700": true,
        "dark:border-gray-700": true,
    },
    transferlist__transferlistSearch: {
        flex: true,
        border: true,
        "border-gray-300": true,
        rounded: true,
        "items-center": true,
        "text-gray-700": true,
        "selection:bg-orange-100": true,
        "dark:border-gray-600": true,
        "dark:text-gray-300": true,
        "dark:selection:bg-orange-100": true,
        "dark:selection:text-gray-700": true,
        "dark:bg-gray-800": true,
    },
    transferlist__transferlistSearchInput: {
        "border-none": true,
        "px-2": true,
        "py-1.5": true,
        "w-full": true,
        "bg-transparent": true,
        "outline-none": true,
        "text-sm": true,
    },
    transferlist__transferlistSearchClear: {
        flex: true,
        "w-[0.75em]": true,
        "mr-2": true,
        "[&_svg]:w-full": true,
    },
    transferlist__controlLabel: {
        absolute: true,
        "opacity-0": true,
        "pointer-events-none": true,
        "text-[0px]": true,
    },
    transferlist__selectedIcon: {
        "w-[0.75em]": true,
        absolute: true,
        "left-[0.5em]": true,
        "select-none": true,
        "text-orange-600": true,
        "dark:text-orange-500": true,
    },
    transferlist__transferlistButton: {
        "sm:w-5": true,
        relative: true,
        flex: true,
        "justify-center": true,
        "text-sm": true,
        "shrink-0": true,
        "box-content": true,
        "text-gray-700": true,
        "disabled:bg-gray-200": true,
        "disabled:!text-gray-400": true,
        "bg-gray-50": true,
        "hover:text-orange-600": true,
        "cursor-pointer": true,
        "appearance-none": true,
        "border-none": true,
        "p-2.5": true,
        "hover:z-10": true,
        "disabled:cursor-not-allowed": true,
        "disabled:opacity-50": true,
        "disabled:hover:text-current": true,
        "disabled:hover:outline-none": true,
        "focus-visible:ring-1": true,
        "focus-visible:ring-orange-500": true,
        "focus-visible:z-10": true,
        "dark:bg-gray-800": true,
        "dark:text-gray-400": true,
        "dark:disabled:!text-gray-600": true,
        "dark:disabled:bg-gray-900": true,
        "dark:disabled:hover:text-current": true,
        "dark:disabled:hover:outline-none": true,
        "dark:hover:text-orange-500": true,
    },
    transferlist__fastForwardIcon: {
        "w-4": true,
        flex: true,
        "select-none": true,
        "[&>svg]:m-auto": true,
        "[&>svg]:w-full": true,
        "[&>svg]:max-w-[1rem]": true,
        "[&>svg]:max-h-[1rem]": true,
        "rotate-90": true,
        "sm:rotate-0": true,
    },
    transferlist__moveRightIcon: {
        "w-4": true,
        flex: true,
        "select-none": true,
        "[&>svg]:m-auto": true,
        "[&>svg]:w-full": true,
        "[&>svg]:max-w-[1rem]": true,
        "[&>svg]:max-h-[1rem]": true,
        "rotate-90": true,
        "sm:rotate-0": true,
    },
    transferlist__moveLeftIcon: {
        "w-4": true,
        flex: true,
        "select-none": true,
        "[&>svg]:m-auto": true,
        "[&>svg]:w-full": true,
        "[&>svg]:max-w-[1rem]": true,
        "[&>svg]:max-h-[1rem]": true,
        "rotate-90": true,
        "sm:rotate-0": true,
    },
    transferlist__rewindIcon: {
        "w-4": true,
        flex: true,
        "select-none": true,
        "[&>svg]:m-auto": true,
        "[&>svg]:w-full": true,
        "[&>svg]:max-w-[1rem]": true,
        "[&>svg]:max-h-[1rem]": true,
        "rotate-90": true,
        "sm:rotate-0": true,
    },
    transferlist__messages: {
        "mt-2": true,
    },
    barcode__barcodeIcon: {
        "w-[1.5em]": true,
        "text-gray-700": true,
        "cursor-pointer": true,
        "dark:text-gray-300": true,
    },
    barcode__dialog: {
        "border-none": true,
        "outline-none": true,
        "overflow-clip": true,
        "p-0": true,
        "bg-black": true,
        rounded: true,
        "w-[100%-2rem]": true,
        "max-w-[30rem]": true,
        "[&::backdrop]:bg-gray-800/50": true,
    },
    barcode__video: {
        "w-full": true,
        "aspect-[1/1]": true,
        "object-cover": true,
        block: true,
        "pointer-events-none": true,
    },
    barcode__closeIcon: {
        "cursor-pointer": true,
        absolute: true,
        "bg-white": true,
        "color-gray-700": true,
        "w-[1.5em]": true,
        "h-[1.5em]": true,
        rounded: true,
        flex: true,
        "top-2": true,
        "right-2": true,
        "z-20": true,
        "[&>svg]:w-[1.25em]": true,
        "[&>svg]:h-[1.25em]": true,
        "[&>svg]:m-auto": true,
    },
    barcode__overlay: {
        "text-gray-700": true,
        "dark:text-gray-300": true,
        absolute: true,
        "top-1/2": true,
        "left-1/2": true,
        "w-[min(20em,75%)]": true,
        "aspect-[1/1]": true,
        "-translate-x-1/2": true,
        "-translate-y-1/2": true,
        rounded: true,
        "pointer-events-none": true,
        "shadow-[0_0_0_999em_rgba(0,0,0,0.5)]": true,
    },
    barcode__overlayDecorators: {
        absolute: true,
        "inset-0": true,
        "z-10": true,
    },
    barcode__overlayDecoratorTopLeft: {
        absolute: true,
        "w-[1.5rem]": true,
        "h-[1.5rem]": true,
        rounded: true,
        "top-0": true,
        "left-0": true,
        "border-l-4": true,
        "border-t-4": true,
        "rounded-tr-none": true,
        "rounded-bl-none": true,
    },
    barcode__overlayDecoratorTopRight: {
        absolute: true,
        "w-[1.5rem]": true,
        "h-[1.5rem]": true,
        rounded: true,
        "top-0": true,
        "right-0": true,
        "border-r-4": true,
        "border-t-4": true,
        "rounded-tl-none": true,
        "rounded-br-none": true,
    },
    barcode__overlayDecoratorBottomRight: {
        absolute: true,
        "w-[1.5rem]": true,
        "h-[1.5rem]": true,
        rounded: true,
        "bottom-0": true,
        "right-0": true,
        "border-r-4": true,
        "border-b-4": true,
        "rounded-tr-none": true,
        "rounded-bl-none": true,
    },
    barcode__overlayDecoratorBottomLeft: {
        absolute: true,
        "w-[1.5rem]": true,
        "h-[1.5rem]": true,
        rounded: true,
        "bottom-0": true,
        "left-0": true,
        "border-l-4": true,
        "border-b-4": true,
        "rounded-tl-none": true,
        "rounded-br-none": true,
    },
    "multi-step__outer": {
        group: true,
        "min-w-0": true,
        grow: true,
        "mb-4": true,
        "data-[disabled]:select-none": true,
        "data-[disabled]:opacity-50": true,
        "text-base": true,
        "group/multistep": true,
        "max-w-[32rem]": true,
    },
    "multi-step__wrapper": {
        "group/wrapper": true,
        "data-[tab-style=tab]:shadow": true,
        "data-[tab-style=tab]:rounded": true,
    },
    "multi-step__tabs": {
        flex: true,
        "items-center": true,
        "group-data-[tab-style=tab]/wrapper:overflow-auto": true,
        "group-data-[tab-style=tab]/wrapper:border": true,
        "group-data-[tab-style=tab]/wrapper:border-b-0": true,
        "group-data-[tab-style=tab]/wrapper:border-gray-300": true,
        "group-data-[tab-style=tab]/wrapper:rounded": true,
        "group-data-[tab-style=tab]/wrapper:rounded-bl-none": true,
        "group-data-[tab-style=tab]/wrapper:rounded-br-none": true,
        "dark:group-data-[tab-style=tab]/wrapper:border-gray-600": true,
        "group-data-[tab-style=progress]/wrapper:my-6": true,
        "group-data-[tab-style=progress]/wrapper:justify-around": true,
        "group-data-[tab-style=progress]/wrapper:overflow-visible": true,
        "group-data-[tab-style=progress]/wrapper:group-data-[hide-labels=true]/wrapper:mb-3.5": true,
    },
    "multi-step__tab": {
        "group/tab": true,
        "group-data-[tab-style=tab]/wrapper:relative": true,
        "group-data-[tab-style=tab]/wrapper:flex": true,
        "group-data-[tab-style=tab]/wrapper:grow": true,
        "group-data-[tab-style=tab]/wrapper:text-sm": true,
        "group-data-[tab-style=tab]/wrapper:items-center": true,
        "group-data-[tab-style=tab]/wrapper:justify-center": true,
        "group-data-[tab-style=tab]/wrapper:cursor-pointer": true,
        "group-data-[tab-style=tab]/wrapper:text-gray-700": true,
        "group-data-[tab-style=tab]/wrapper:bg-gray-100": true,
        "group-data-[tab-style=tab]/wrapper:py-3.5": true,
        "group-data-[tab-style=tab]/wrapper:px-4": true,
        "group-data-[tab-style=tab]/wrapper:border-r": true,
        "group-data-[tab-style=tab]/wrapper:border-b": true,
        "group-data-[tab-style=tab]/wrapper:border-gray-300": true,
        "group-data-[tab-style=tab]/wrapper:last:border-r-0": true,
        "group-data-[tab-style=tab]/wrapper:shadow-[inset_0_-0.5em_0.5em_-0.5em_rgba(0,0,0,0.1)]": true,
        "group-data-[tab-style=tab]/wrapper:data-[active=true]:bg-white": true,
        "group-data-[tab-style=tab]/wrapper:data-[active=true]:font-bold": true,
        "group-data-[tab-style=tab]/wrapper:data-[active=true]:border-b-white": true,
        "group-data-[tab-style=tab]/wrapper:data-[active=true]:z-10": true,
        "group-data-[tab-style=tab]/wrapper:data-[active=true]:shadow-[0_0_0.5em_0_rgba(0,0,0,0.1)]": true,
        "dark:group-data-[tab-style=tab]/wrapper:text-gray-300": true,
        "dark:group-data-[tab-style=tab]/wrapper:bg-gray-950/20": true,
        "dark:group-data-[tab-style=tab]/wrapper:data-[active=true]:bg-transparent": true,
        "dark:group-data-[tab-style=tab]/wrapper:data-[active=true]:border-b-transparent": true,
        "dark:group-data-[tab-style=tab]/wrapper:border-gray-600": true,
        "group-data-[tab-style=progress]/wrapper:flex": true,
        "group-data-[tab-style=progress]/wrapper:flex-col": true,
        "group-data-[tab-style=progress]/wrapper:items-center": true,
        "group-data-[tab-style=progress]/wrapper:grow": true,
        "group-data-[tab-style=progress]/wrapper:shrink-0": true,
        "group-data-[tab-style=progress]/wrapper:relative": true,
        "group-data-[tab-style=progress]/wrapper:before:block": true,
        "group-data-[tab-style=progress]/wrapper:before:text-sm": true,
        "group-data-[tab-style=progress]/wrapper:before:w-[1.25rem]": true,
        "group-data-[tab-style=progress]/wrapper:before:h-[1.25rem]": true,
        "group-data-[tab-style=progress]/wrapper:before:border-4": true,
        "group-data-[tab-style=progress]/wrapper:before:border-gray-300": true,
        "group-data-[tab-style=progress]/wrapper:before:rounded-full": true,
        "group-data-[tab-style=progress]/wrapper:before:bg-white": true,
        "group-data-[tab-style=progress]/wrapper:before:z-10": true,
        "dark:group-data-[tab-style=progress]/wrapper:before:border-gray-600": true,
        "dark:group-data-[tab-style=progress]/wrapper:before:bg-gray-950": true,
        "group-data-[tab-style=progress]/wrapper:after:block": true,
        "group-data-[tab-style=progress]/wrapper:after:h-1": true,
        "group-data-[tab-style=progress]/wrapper:after:w-full": true,
        "group-data-[tab-style=progress]/wrapper:after:absolute": true,
        "group-data-[tab-style=progress]/wrapper:after:top-[0.5em]": true,
        "group-data-[tab-style=progress]/wrapper:after:left-[calc(50%+0.5em)]": true,
        "group-data-[tab-style=progress]/wrapper:after:bg-gray-300": true,
        "group-data-[tab-style=progress]/wrapper:data-[valid=true]:data-[visited=true]:after:bg-orange-600": true,
        "group-data-[tab-style=progress]/wrapper:last:after:hidden": true,
        "dark:group-data-[tab-style=progress]/wrapper:after:bg-gray-600": true,
        "dark:group-data-[tab-style=progress]/wrapper:data-[valid=true]:data-[visited=true]:after:bg-orange-600": true,
    },
    "multi-step__tabLabel": {
        "group-data-[tab-style=progress]/wrapper:absolute": true,
        "group-data-[tab-style=progress]/wrapper:text-gray-800": true,
        "group-data-[tab-style=progress]/wrapper:top-full": true,
        "group-data-[tab-style=progress]/wrapper:w-full": true,
        "group-data-[tab-style=progress]/wrapper:whitespace-nowrap": true,
        "group-data-[tab-style=progress]/wrapper:text-xs": true,
        "dark:group-data-[tab-style=progress]/wrapper:text-gray-300": true,
    },
    "multi-step__badge": {
        "bg-red-600": true,
        absolute: true,
        "font-mono": true,
        "font-bold": true,
        flex: true,
        "items-center": true,
        "justify-center": true,
        "aspect-[1/1]": true,
        "[line-height:1.25rem]": true,
        "text-white": true,
        "rounded-full": true,
        "group-data-[valid=true]/tab:bg-orange-600": true,
        "group-data-[tab-style=tab]/wrapper:text-[0.66rem]": true,
        "group-data-[tab-style=tab]/wrapper:p-1.5": true,
        "group-data-[tab-style=tab]/wrapper:w-5": true,
        "group-data-[tab-style=tab]/wrapper:h-5": true,
        "group-data-[tab-style=tab]/wrapper:top-1.5": true,
        "group-data-[tab-style=tab]/wrapper:right-1.5": true,
        "group-data-[tab-style=progress]/wrapper:w-[1.25rem]": true,
        "group-data-[tab-style=progress]/wrapper:h-[1.25rem]": true,
        "group-data-[tab-style=progress]/wrapper:p-1": true,
        "group-data-[tab-style=progress]/wrapper:text-[10px]": true,
        "group-data-[tab-style=progress]/wrapper:[line-height:0]": true,
        "group-data-[tab-style=progress]/wrapper:z-10": true,
    },
    "multi-step__validStepIcon": {
        "w-full": true,
        "h-full": true,
        "mt-0.5": true,
    },
    "multi-step__steps": {
        "px-10": true,
        "pt-8": true,
        "pb-4": true,
        "bg-white": true,
        border: true,
        "border-gray-300": true,
        rounded: true,
        "dark:bg-transparent": true,
        "dark:border-gray-600": true,
        "group-data-[tab-style=tab]/wrapper:border-t-0": true,
        "group-data-[tab-style=tab]/wrapper:rounded-tl-none": true,
        "group-data-[tab-style=tab]/wrapper:rounded-tr-none": true,
        "group-data-[tab-style=progress]/wrapper:shadow": true,
        "[&_[data-type]]:max-w-none": true,
    },
    step__stepActions: {
        flex: true,
        "justify-between": true,
        "[&>*]:grow-0": true,
    },
    step__stepPrevious: {
        "mr-1.5": true,
    },
    step__stepNext: {
        "ml-auto": true,
    },
};

/**
 * Globals are merged prior to generating this file — these are included for
 * any other non-matching inputs.
 **/
const globals = {
    outer: {
        group: true,
        "max-w-none": true,
        "min-w-0": true,
        grow: true,
        "mb-4": true,
        "data-[disabled]:select-none": true,
        "data-[disabled]:opacity-50": true,
        "text-base": true,
    },
    label: {
        block: true,
        "text-gray-700": true,
        "text-sm": true,
        "font-bold": true,
        "mb-1": true,
        "dark:text-gray-300": true,
    },
    legend: {
        block: true,
        "text-gray-700": true,
        "text-sm": true,
        "font-bold": true,
        "dark:text-gray-300": true,
    },
    input: {
        "appearance-none": true,
        "[color-scheme:light]": true,
        "dark:[color-scheme:dark]": true,
        "selection:bg-orange-100": true,
        "selection:text-gray-700": true,
        "group-data-[has-overlay]:selection:!text-transparent": true,
    },
    prefixIcon: {
        flex: true,
        "items-center": true,
        "-ml-1": true,
        "mr-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
    },
    suffixIcon: {
        flex: true,
        "items-center": true,
        "-mr-1": true,
        "ml-2": true,
        "text-base": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
    },
    loaderIcon: {
        "animate-spin": true,
        flex: true,
        "items-center": true,
        "my-auto": true,
        "ml-2": true,
        "text-base": true,
        "text-gray-500": true,
        "h-[1em]": true,
        "w-[1em]": true,
        "shrink-0": true,
        "[&>svg]:w-full": true,
    },
    loadMoreInner: {
        flex: true,
        "text-sm": true,
        "text-gray-500": true,
        "p-2": true,
        "items-center": true,
        "justify-center": true,
        "[&>span]:mr-2": true,
    },
    help: {
        "text-gray-500": true,
        "text-xs": true,
        "dark:text-gray-400": true,
    },
    message: {
        "text-red-600": true,
        "mb-1.5": true,
        "text-xs": true,
        "dark:text-red-400": true,
    },
    overlay: {
        "text-gray-700": true,
        "dark:text-gray-300": true,
    },
    overlayPlaceholder: {
        "text-gray-400": true,
        "dark:text-gray-400/50": true,
    },
    overlayLiteral: {
        "text-gray-700": true,
        "dark:text-gray-300": true,
    },
    overlayChar: {
        "text-gray-700": true,
        "dark:text-gray-300": true,
    },
    overlayEnum: {
        "text-gray-700": true,
        "dark:text-gray-300": true,
    },
};
