import { ref, computed, inject } from "vue";
import { defineStore } from "pinia";
import GeoJSON from "ol/format/GeoJSON";
import { generateBusTableHTML, generateBusTableXML } from "@/analyses/busTimetables";
import {
    generateRailStationsTableHTML,
    generateRailStationsXMLString,
    generateRailServicesTableHTML,
    generateRailServicesXMLString,
    generateRailStationAmenitiesHTML,
} from "../analyses/railStations";
import { generateCollisionSummaryTable } from "@/analyses/collisions";
import { highlightedBusStopStyle } from "../maps/constants";
import { formatTimeAsLocalTime } from "@/utils";

import { Vector as VectorSource } from "ol/source";
import { Style } from "ol/style";

import { useEventBus } from "@vueuse/core";

export const useFormDataStore = defineStore("FormData", () => {
    const axios = inject("axios");
    const formData = ref({});
    const isLoading = ref(false);
    const isSaving = ref(false);
    const formDataIsReady = ref(false);
    const lastModified = ref(null);
    const maps = ref({});
    const selectedPolicyDocumentIndex = ref(null);

    const errorBus = useEventBus("error");

    const longitude = computed(() => {
        if (formData.value.latlong) {
            return formData.value.latlong.split(",")[1].trim();
        } else {
            return -1.9028874721719107; // Default location = Swallow Street
        }
    });

    const latitude = computed(() => {
        if (formData.value.latlong) {
            return formData.value.latlong.split(",")[0].trim();
        } else {
            return 52.47869083180635; // Default location = Swallow Street
        }
    });

    const policyIsLoading = ref(false);

    const railStationsTableHTML = computed(() => {
        return generateRailStationsTableHTML(formData.value.rail_stations, formData.value.tram_metro_stops);
    });

    const railStationsTableXML = computed(() => {
        return generateRailStationsXMLString(formData.value.rail_stations, formData.value.tram_metro_stops);
    });

    const railServicesTableHTML = computed(() => {
        return generateRailServicesTableHTML(formData.value.rail_stations);
    });

    const railServicesTableXML = computed(() => {
        return generateRailServicesXMLString(formData.value.rail_stations);
    });

    const railStationAmenitiesHTML = computed(() => {
        return generateRailStationAmenitiesHTML(formData.value.rail_stations);
    });

    const collisionSummaryTableHTML = computed(() => {
        return generateCollisionSummaryTable({
            slight: formData.value.slight_collisions,
            serious: formData.value.serious_collisions,
            fatal: formData.value.fatal_collisions,
            total: formData.value.total_collisions,
        });
    });

    async function loadSummaryFields(id = null) {
        // If id paramter is given, use it to select transport statement, else
        // use the current route
        if (id == null) {
            id = this.router.currentRoute.value.params.id;
        }

        const apiUrl = `/api/transport_statements/project/summary/${id}`;

        // Set loading state to true
        isLoading.value = true;

        let response = await axios
            .get(apiUrl)
            .catch((error) => {
                console.error("Error fetching data:", error);
                if (error.response.status === 404) {
                    this.router.push({ name: "portal" });
                }
                errorBus.emit("Error loading project. Please try again.");
            })
            .finally(() => {
                // Set loading state to false when the response is received
                isLoading.value = false;
            });

        // Update the form data with the received JSON response
        formData.value = response.data;
        formDataIsReady.value = true;
    }

    async function loadFields(id = null) {
        // If id paramter is given, use it to select transport statement, else
        // use the current route
        if (id == null) {
            id = this.router.currentRoute.value.params.id;
        }

        const apiUrl = `/api/transport_statements/${id}`;

        // Set loading state to true
        isLoading.value = true;

        let response = await axios
            .get(apiUrl)
            .catch((error) => {
                console.error("Error fetching data:", error);
                if (error.response.status === 404) {
                    this.router.push({ name: "portal" });
                }
                errorBus.emit("Error loading project. Please try again.");
            })
            .finally(() => {
                // Set loading state to false when the response is received
                isLoading.value = false;
            });

        // Update the form data with the received JSON response
        formData.value = response.data;
        lastModified.value = formatTimeAsLocalTime(response.data.user_last_modified);
        formDataIsReady.value = true;
    }

    async function patchRecord(id = null) {
        // If id paramter is given, use it to select transport statement, else
        // use the current route
        if (id == null) {
            id = this.router.currentRoute.value.params.id;
        }

        const apiUrl = `/api/transport_statements/${id}`;

        isSaving.value = true;

        // Send a PATCH request with the payload
        return axios
            .patch(apiUrl, formData.value)
            .then((response) => {
                lastModified.value = formatTimeAsLocalTime(response.data.user_last_modified);
            })
            .catch((error) => {
                console.error("Error sending PATCH request:", error);
                if (error.response.status === 404) {
                    this.router.push({ name: "portal" });
                }

                errorBus.emit("Error saving project. Please try again.");
            })
            .finally(() => {
                isSaving.value = false;
            });
    }

    const fetchBusStops = async () => {
        const apiUrl = "/api/amenities/bus_stops";
        const [min_longitude, min_latitude, max_longitude, max_latitude] = maps.value.bus_stops_map
            .getMap()
            .getView()
            .calculateExtent();
        const params = {
            latitude: formData.value.analysis_latitude,
            longitude: formData.value.analysis_longitude,
            min_longitude: min_longitude,
            min_latitude: min_latitude,
            max_longitude: max_longitude,
            max_latitude: max_latitude,
        };
        loadingBusStops.value = true;
        let response = await axios
            .get(apiUrl, { params: params })
            .catch((error) => {
                console.error("Error sending GET request:", error);
                errorBus.emit("Error fetching bus stops. Please try again.");
            })
            .finally(() => (loadingBusStops.value = false));

        formData.value.bus_stops_map.bus_stops_geojson = response.data;
        const amenities_geojson = {
            bus_stop: formData.value.bus_stops_map.bus_stops_geojson,
        };
        maps.value.bus_stops_map.setAmenities(amenities_geojson);
    };

    const fetchIsochrones = async (mapKey) => {
        const profile = formData.value[mapKey].isochrone_config.profile;
        const range_list = formData.value[mapKey].isochrone_config.range_list;
        const range_type = formData.value[mapKey].isochrone_config.range_type;

        // If isochrone config has origin lat and long set, use them, else use
        // default site location point
        let origin_latitude =
            formData.value[mapKey].isochrone_config.origin_latitude || formData.value.analysis_latitude;
        let origin_longitude =
            formData.value[mapKey].isochrone_config.origin_longitude || formData.value.analysis_longitude;

        const params = {
            latitude: origin_latitude,
            longitude: origin_longitude,
            profile: profile,
            range: range_list,
            range_type,
        };
        isochroneIsGenerating.value = true;
        return axios
            .get("/api/isochrone", {
                params: params,
                paramsSerializer: { indexes: null },
            })
            .then((response) => {
                const isochrones = response.data;

                formData.value[mapKey].isochrone_geojson = isochrones;
            })
            .catch((error) => {
                console.error("Error sending GET request:", error);

                errorBus.emit("Error fetching isochrones. Please try again.");
            })
            .finally(() => {
                isochroneIsGenerating.value = false;
            });
    };

    const loadingRailStations = ref(null);
    const loadingRailStationAmenitiesSummary = ref(null);
    const loadingBusStops = ref(null);
    const loadingBusTimetables = ref(null);

    async function fetchRailStationsNearestToPoint(longitude, latitude) {
        const params = {
            latitude: latitude,
            longitude: longitude,
        };
        const response = await axios.get("/api/amenities/nearest_rail_stations", { params: params }).catch((error) => {
            console.error("Error sending GET request:", error);
            errorBus.emit("Error fetching nearest rail stations. Please try again.");
        });
        return response.data;
    }

    async function fetchRailStations() {
        const [minLong, minLat, maxLong, maxLat] = maps.value.rail_stations_map.getMap().getView().calculateExtent();

        const railRequest = {
            url: "/api/amenities/rail_services",
            params: {
                site_latitude: formData.value.analysis_latitude,
                site_longitude: formData.value.analysis_longitude,
                min_longitude: minLong,
                min_latitude: minLat,
                max_longitude: maxLong,
                max_latitude: maxLat,
            },
            resultLoc: "rail_stations",
        };

        const metroRequest = {
            url: "/api/amenities/metro_services",
            params: {
                site_latitude: formData.value.analysis_latitude,
                site_longitude: formData.value.analysis_longitude,
                min_longitude: minLong,
                min_latitude: minLat,
                max_longitude: maxLong,
                max_latitude: maxLat,
            },
            resultLoc: "tram_metro_stops",
        };

        const requests = [railRequest, metroRequest];

        loadingRailStations.value = true;

        Promise.all(
            requests.map((req) =>
                axios.get(req.url, { params: req.params }).catch((error) => {
                    console.error("Error sending GET request:", error);
                    errorBus.emit("Error fetching rail stations. Please try again.");
                })
            )
        )
            .then((responses) => {
                responses.forEach((response, idx) => {
                    if (response.data.length == 0) {
                        // no stations of this type on map
                        formData.value[requests[idx].resultLoc] = [];
                    } else {
                        formData.value[requests[idx].resultLoc] = response.data;
                    }
                });
            })

            .finally(() => {
                loadingRailStations.value = false;
            });
    }

    async function fetchBusTimetables() {
        const apiUrl = "/api/bus_timetables/";
        loadingBusTimetables.value = true;
        const hiddenBusStops = formData.value.bus_timetables.filter((stop) => !stop.included).map((stop) => stop.id);
        const hiddenBusStopsSet = new Set(hiddenBusStops);

        let stops = [];
        const extent = maps.value.bus_stops_map.getMap().getView().calculateExtent();
        maps.value.bus_stops_map.busStopsSource.forEachFeatureInExtent(extent, function (feature) {
            stops.push(feature.getId());
        });

        await axios
            .post(apiUrl, {
                stops: stops,
                latitude: formData.value.analysis_latitude,
                longitude: formData.value.analysis_longitude,
            })
            .then((response) => {
                let bus_timetables = response.data.stops;
                // Loop over new bus timetables
                // Show each stop on the map
                // Check if it was previously hidden - if so, hide it
                // TODO hide hidden stops when loading map from scratch
                bus_timetables.forEach((stop) => {
                    maps.value.bus_stops_map.busStopsSource.getFeatureById(stop.id).setProperties({ visible: true });
                    if (hiddenBusStopsSet.has(stop.id)) {
                        stop.included = false;
                        maps.value.bus_stops_map.busStopsSource
                            .getFeatureById(stop.id)
                            .setProperties({ visible: false });
                    }
                });
                formData.value.bus_timetables = bus_timetables;
            })
            .catch((error) => {
                console.error("Error sending POST request:", error);

                errorBus.emit("Error fetching bus timetables. Please try again.");
            })
            .finally(() => {
                loadingBusTimetables.value = false;
            });
    }

    async function refreshBusServices() {
        const zoom = maps.value.bus_stops_map.getMap().getView().getZoom();
        if (zoom >= 14) {
            await fetchBusTimetables();
        } else {
            formData.value.bus_timetables = [];
        }
    }

    async function fetchLocalPolicy() {
        const apiUrl = "/api/policy";
        policyIsLoading.value = true;
        await axios
            .get(apiUrl, {
                params: {
                    local_authority: formData.value.local_planning_authority,
                },
            })
            .then((response) => {
                formData.value.policy_documents = response.data.documents;
                selectedPolicyDocumentIndex.value = 0;
            })
            .catch((error) => {
                console.error("Error sending GET request:", error);
                errorBus.emit("Error fetching local policy. Please try again.");
            })
            .finally(() => {
                policyIsLoading.value = false;
            });
    }

    const redlinePolygonSource = new VectorSource({ wrapX: false });

    function updateRedlinePolygon() {
        redlinePolygonSource.clear();
        if (formData.value.redline_polygon_geojson) {
            const features = new GeoJSON({
                featureProjection: "EPSG:3857",
                dataProjection: "EPSG:3857",
            }).readFeatures(JSON.parse(formData.value.redline_polygon_geojson));
            redlinePolygonSource.addFeatures(features);
        } else {
            const format = new GeoJSON({
                featureProjection: "EPSG:3857",
                dataProjection: "EPSG:3857",
            });
            formData.value.redline_polygon_geojson = format.writeFeatures([]);
        }
    }

    /////////////////////////
    // Bus Timetables      //
    /////////////////////////

    const busTableHTML = computed(() => {
        return generateBusTableHTML(formData.value.bus_timetables);
    });

    const busTableXML = computed(() => {
        return generateBusTableXML(formData.value.bus_timetables);
    });

    const highlightedBusStop = ref(null);

    const highlightBusStop = (atcocode) => {
        // Highlight bus stop on map
        maps.value.bus_stops_map.busStopsSource.getFeatureById(atcocode).setStyle(highlightedBusStopStyle);

        // Highlight bus stop in list
        highlightedBusStop.value = atcocode;
    };

    const resetBusStopStyle = (atcocode) => {
        // Reset style of bus stop on map
        let feature = maps.value.bus_stops_map.busStopsSource.getFeatureById(atcocode);

        if (feature.get("visible")) {
            feature.setStyle(null);
        } else {
            feature.setStyle(new Style({}));
        }
    };

    const unhighlightBusStop = (atcocode) => {
        // Reset style of bus stop on map
        resetBusStopStyle(atcocode);

        // unhighlight bus stop in list
        highlightedBusStop.value = null;
    };

    const deduplicateBusStops = () => {
        const uniqueServiceMap = new Map(); // To track unique services

        // For each bus stop, check all the services
        // If the service is the first appearance of that service, include the service AND include the bus stop
        // else exclude the service
        // (i.e bus stops only require ONE included service for the stop be included)

        // TODO when a user unchecks bus stop #1, how do we re-include the services that were hidden?
        // idea 1 - checkboxes/toggles for each service on each stop
        // idea 2 - always (re-)dedupe services AFTER deduping stops

        // => go with idea 2 for now
        formData.value.bus_timetables.forEach((busStop) => {
            busStop.included = false;

            busStop.services.forEach((service) => {
                const serviceId = `${service.operator_code}-${service.route_short_name}-${service.route_description}`;
                // If this service hasn't been seen before
                if (!uniqueServiceMap.has(serviceId)) {
                    uniqueServiceMap.set(serviceId, true); // Mark this service as seen (add to uniqueServiceMap)
                    busStop.included = true;
                    service.included = true;
                } else {
                    service.included = false;
                }
            });

            // Update bus stop on map
            maps.value.bus_stops_map.busStopsSource
                .getFeatureById(busStop.id)
                .setProperties({ visible: busStop.included });
            resetBusStopStyle(busStop.id);
        });
    };

    const fetchCollisionLocations = async (regionConfig) => {
        const apiUrl = regionConfig.apiUrls.collisions_source;

        // If there is a distinct analysis point set for accident map, use it.
        // Otherwise, use the scheme analysis point
        const accidentAnalysisLocation = {
            latitude: formData.value.collision_map?.analysis_location?.latitude ?? formData.value.analysis_latitude,
            longitude: formData.value.collision_map?.analysis_location?.longitude ?? formData.value.analysis_longitude,
        };

        const params = {
            latitude: accidentAnalysisLocation.latitude,
            longitude: accidentAnalysisLocation.longitude,
            radius: formData.value.collision_map?.radius ?? 300,
        };

        fetchingCollisionLocations.value = true;

        axios
            .get(apiUrl, { params: params })
            .then((response) => {
                let collisions_geojson = response.data;

                if (!collisions_geojson?.features.length) {
                    // If no accident locations returned, set stored value to
                    // null and remove any current accidents from map
                    formData.value.collision_locations = null;
                    maps.value.collision_map.clearRoadAccidents();
                } else {
                    // If accident locations returned, store accident locations
                    // and set on map
                    formData.value.collision_locations = collisions_geojson;
                    maps.value.collision_map.setRoadAccidents(collisions_geojson);
                }

                // Update numbers of collisions by category
                const accidentCounter = {
                    fatal: 0,
                    serious: 0,
                    slight: 0,
                };

                collisions_geojson.features.forEach((feature) => {
                    ++accidentCounter[feature.properties.severity];
                });

                formData.value.fatal_collisions = accidentCounter.fatal;
                formData.value.serious_collisions = accidentCounter.serious;
                formData.value.slight_collisions = accidentCounter.slight;
                formData.value.total_collisions =
                    accidentCounter.fatal + accidentCounter.serious + accidentCounter.slight;
            })
            .finally(() => (fetchingCollisionLocations.value = false));
    };

    const fetchingCollisionLocations = ref(false);

    const isochroneIsGenerating = ref(false);

    const mapsReadyForExport = ref({});

    const routeToProjectFromPortal = ref(true);

    const snippets = ref([]);
    const loadingSnippets = ref(false);
    const totalSnippets = ref(null);

    async function searchSnippets(searchQuery, snippetType, resultCount = 10, resultPage = 1, chapter = null) {
        loadingSnippets.value = true;

        const apiUrl = "/api/snippets/search";

        const params = {
            snippet_type: snippetType,
            search_query: searchQuery ?? "",
            result_count: resultCount,
            result_page: resultPage,
            report_chapter: chapter,
        };

        axios
            .get(apiUrl, { params: params })
            .then((response) => {
                snippets.value = response.data.snippets;
                totalSnippets.value = response.data.count;
            })
            .catch((error) => {
                console.error("Error sending GET request:", error);
                errorBus.emit("Error fetching snippets. Please try again.");
            })
            .finally(() => {
                loadingSnippets.value = false;
            });
    }

    async function deleteSnippet(snippetId) {
        const apiUrl = `/api/snippets/${snippetId}`;

        try {
            await axios.delete(apiUrl);

            // Delete from stored snippets list, which will trigger a UI update
            const idx = snippets.value.findIndex((snippet) => snippet.snippet_id == snippetId);
            if (idx == -1) {
                console.error(`Snippet ${snippetId} to be deleted not found.`);
            } else {
                snippets.value.splice(idx, 1);
            }
        } catch (error) {
            console.error(`Error deleting snippet ${snippetId}, DELETE request:`, error);
            errorBus.emit("Error deleting snippet. Please try again.");
        }
    }

    return {
        mapsReadyForExport,
        policyIsLoading,
        loadingRailStations,
        loadingRailStationAmenitiesSummary,
        loadingBusStops,
        loadingBusTimetables,
        fetchLocalPolicy,
        fetchIsochrones,
        redlinePolygonSource,
        formData,
        isLoading,
        isSaving,
        formDataIsReady,
        longitude,
        latitude,
        lastModified,
        loadFields,
        patchRecord,
        fetchBusStops,
        fetchBusTimetables,
        fetchRailStations,
        fetchRailStationsNearestToPoint,
        maps,
        updateRedlinePolygon,
        railStationsTableHTML,
        railStationsTableXML,
        railServicesTableHTML,
        railServicesTableXML,
        railStationAmenitiesHTML,
        busTableHTML,
        busTableXML,
        collisionSummaryTableHTML,
        refreshBusServices,
        highlightedBusStop,
        highlightBusStop,
        unhighlightBusStop,
        deduplicateBusStops,
        isochroneIsGenerating,
        selectedPolicyDocumentIndex,
        fetchCollisionLocations,
        fetchingCollisionLocations,
        routeToProjectFromPortal,
        loadSummaryFields,
        snippets,
        loadingSnippets,
        totalSnippets,
        searchSnippets,
        deleteSnippet,
    };
});
